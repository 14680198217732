import axios from 'axios';


const baseURL = process.env.REACT_APP_API_BASE_URL;
const instance = axios.create({ 
    baseURL: baseURL,
    withCredentials: true  
});

instance.interceptors.response.use(
    response => (response), 
    error => (Promise.reject(error))
  )

export default instance;
