import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import HeroSection from './components/HeroSection';
import CourseSection from './components/CourseSection';
import FeatureSection from './components/FeatureSection';
import StatSection from './components/StatSection';
import Testimonial from './components/Testimonial';
import Footer from './components/Footer';
import WarningAlert from '../../components/WarningAlert';
import { useEffect } from 'react';


export default function HomePage() {
  const navigate = useNavigate();
  const userState = useSelector((state) => state.user);
  const currentUser = userState?.userInfo || null;

  useEffect(() => {
    if (currentUser) {
      navigate('/learn/home');
    }
  }, []);
  
  return (
    <div>
      {/* <div>
        <WarningAlert message="This is site is under contruction. We appreciate your interests on visiting us. All the feature will be live soon..." />
      </div> */}
      <HeroSection />
      {/* <StatSection /> */}
      <FeatureSection />
      {/* <CourseSection /> */}
      {/* <Testimonial /> */}
      <Footer />
    </div>
  )
};

