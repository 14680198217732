import { useState } from 'react';
import toast from 'react-hot-toast';
import { isEmpty } from 'lodash';

import StackedLayout from '../../layouts/StackedLayout';
import { Input } from '../../components/forms/fields';
import { SubmitButton } from '../../components/buttons';
import { changePassword } from '../../services/user';


const sidebarNavigation = [
  {name: 'প্রোফাইল', href: '/user/profile' },
  {name: 'পাসওয়ার্ড পরিবর্তন', href: '/user/change-password' },
];

export default function PasswordChangePage() {
  const [newPassword, setNewPassword] = useState(null);
  const [confirmNewPassword, setConfirmNewPassword] = useState(null);
  const [updating, setUpdating] = useState(false);

  const handleChangePassword = async (event) => {
    try {
      event.preventDefault();
      setUpdating(true);
      await changePassword({newPassword: newPassword});
      toast.success('পাসওয়ার্ড সফলভাবে পরিবর্তন হয়েছে');
      setNewPassword(null);
      setConfirmNewPassword(null);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setUpdating(false);
    }
  };

  const isDisabled = () => {
    return updating || isEmpty(newPassword) || isEmpty(confirmNewPassword) || newPassword !== confirmNewPassword;
  };

  return (
    <StackedLayout
      sidebarNavigation={sidebarNavigation}
      currentSidebarNavigation="পাসওয়ার্ড পরিবর্তন"
    >
      <div className="border-b border-gray-200 pb-5">
        <h3 className="text-base font-semibold leading-6 text-gray-900">পাসওয়ার্ড পরিবর্তন</h3>
      </div>
      <div className="flex flex-col w-full space-y-6">
        <Input type="password" label="নতুন পাসওয়ার্ড" value={newPassword} onChange={(event) => setNewPassword(event.target.value)} />
        <Input type="password" label="পাসওয়ার্ড পুনরায় লিখুন" value={confirmNewPassword} onChange={(event) => setConfirmNewPassword(event.target.value)} />
        
        <div className="w-full">
          <SubmitButton disabled={isDisabled()} action="সাবমিট করুন" onClick={handleChangePassword} />
        </div>
      </div>
    </StackedLayout>
  )

};
