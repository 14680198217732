import StackedLayout from '../../layouts/StackedLayout';


export default function WelcomePage() {
  return (
    <StackedLayout>
      <div>
        <div className="px-6 py-24 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
          <h2 className="mb-4 text-2xl font-bold tracking-tight text-gray-900">স্বাগতম!</h2>
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              সেরা প্রস্তুতি নিশ্চিত নিন
              <br />
              দেশসেরা গুরুদের সাথে
            </h2>
            <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
              দেশসেরা সকল শিক্ষক ও কোচিং এর লাইভ ও রেকর্ডেড কোর্স এবং মডেল টেস্ট এখন এক প্লাটফর্মে।
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6 w-full sm:w-auto">
              <a
                href="/learn/home"
                className="w-full sm:w-auto rounded-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
              >
                প্রস্তুতি শুরু করুন
              </a>
            </div>
          </div>
        </div>
      </div>
    </StackedLayout>
  )
};