import { Button } from '../components/buttons';
import {
  gradeLabelMapping, 
  programIconMapping, 
  groupLabelMapping, 
  getBanglaDate 
} from '../utils';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
};

export default function Sidebar(props) {
  const {
    page,
    currentUser,
    enrolledCourseData,
    navigation,
    currentNavigation,
    onNavigationChange
  } = props;

  const handleNavigationChange = (event, index) => {
    index = index >= 0 ? index : event.target.selectedIndex;
    const item = navigation[index];
    
    if (item?.href) {
      window.location.href = item.href;
    } else {
      onNavigationChange(event, index);
    }
  };

  return (
    <nav className="flex flex-1 flex-col py-4 lg:block lg:w-64 lg:flex-none lg:border-0" aria-label="Sidebar">
      {currentUser && page === 'learn' &&
      <PreferenceCard
        currentUser={currentUser}
        onClick={() => {
          window.location.href = `/onboarding/preferences`;
        }}
      />}

      {page === 'course' &&
      <CourseCard enrolledCourseData={enrolledCourseData} />}

      <div className="sm:hidden">
        <select
          className="block px-2 w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
          value={currentNavigation}
          onChange={(event) => handleNavigationChange(event)}
        >
          {navigation.map((item, index) => (
            <option
              key={`sidebar-nav-index[${index}]`}
              value={item?.name || item}
            >
              {item?.name || item}
            </option>
          ))}
        </select>
      </div>

      <ul role="list" className="hidden sm:flex flex-1 flex-col gap-y-7">
        <li>
          <ul role="list" className=" space-y-1">
            {navigation?.length > 0 && navigation.map((item, index) => (
              <li key={`sidebar-nav-index[${index}]`}>
                <a
                  href="#"
                  className={classNames(
                    (item?.name === currentNavigation || item === currentNavigation)
                      ? 'bg-gray-50 text-green-600'
                      : 'text-gray-700 hover:text-green-600 hover:bg-gray-50',
                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                  )}
                  onClick={(event) => handleNavigationChange(event, index)}
                >
                  {item?.name || item}
                </a>
              </li>
            ))}
          </ul>
        </li>
      </ul>
    </nav>
  )
};

function PreferenceCard(props) {
  const { currentUser, onClick } = props;
  const {grade, group, session } = currentUser?.preference;
  
  return (
    <div className='flex flex-col bg-white mb-8 pt-2 content-center justify-center items-center'>
      <div className='flex flex-row items-center mb-2'>
          <img className='w-8 h-8 mr-2' src={programIconMapping[grade]} />
          <div className="inline-flex flex-col space-y-0.5 items-start justify-end">
              <p className="text-xs text-gray-900">
                {gradeLabelMapping[grade]}
                <span>{grade !== 'job' && ` (${groupLabelMapping[group]})`}</span>
              </p>
              <p className="text-xs text-gray-400">
                {grade !== 'job' && <span>সেশনঃ {session}</span>}
                {grade === 'job' && <span>বিসিএস, প্রাইমারি শিক্ষক ও অন্যান্য</span>}
              </p>
          </div>
      </div>
      <div className="w-52">
          <Button
            className="w-full"
            label="পরিবর্তন করুন"
            onClick={onClick}
          />
      </div>
  </div>
  )
};

function CourseCard(props) {
  const { enrolledCourseData } = props;
  
  return (
    <div className='m-0 flex flex-col rounded-md bg-gray-50 py-4 mb-4 content-center justify-center items-center'>
      <div className='flex flex-row items-center'>
          <div className="inline-flex flex-col px-2 space-y-0.5 items-start justify-end">
              <p className="text-gray-900">
                {enrolledCourseData?.courseName}
              </p>
              {enrolledCourseData?.batchName &&
              <p className="text-sm text-gray-400">
                ব্যাচঃ {enrolledCourseData?.batchName}
              </p>}
              {enrolledCourseData?.expiresAt &&
              <p className="text-sm text-indigo-400">
                কোর্সটির মেয়াদ {getBanglaDate(enrolledCourseData.expiresAt)} পর্যন্ত
              </p>}
          </div>
      </div>
  </div>
  )
};
