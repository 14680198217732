import { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { isEmpty, includes } from 'lodash';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, BellIcon, XMarkIcon, UserIcon } from '@heroicons/react/24/outline';

import { logout } from '../services/auth';
import { userActions } from '../store/reducers/userReducers';
import { images } from '../constants';
import { LoginButton } from '../components/buttons';


const userNavigation = [
  { name: 'প্রোফাইল', href: '/user/profile' },
  // { name: 'Settings', href: '#' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Header(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = props.currentUser;

  const handleLogout = async (event) => {
    event.preventDefault();
    try {
      await logout();
      dispatch(userActions.resetUserInfo());
      navigate('/auth/login');
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <>
      <Disclosure as="nav" className="fixed top-0 w-full border-b border-gray-200 bg-white">
          {({ open }) => (
            <>
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 justify-between">
                  <div className="flex">
                    <div
                      className="flex flex-shrink-0 items-center cursor-pointer"
                      onClick={() => {
                        const currentUrl = window.location.href;
                        const urlsToExclude = ['onboarding', 'checkout'];
                        if (!urlsToExclude.some(url => includes(currentUrl, url))) {
                          window.location.href = currentUser ? '/learn/home' : '/';
                        }
                      }}
                    >
                      <img
                        className="block h-10 w-auto lg:hidden"
                        src={images.BGIcon}
                        alt="Your Company"
                      />
                      <img
                        className="hidden h-12 w-auto lg:block"
                        src={images.Logo}
                        alt="Your Company"
                      />
                    </div>
                  </div>
                  
                  <div className="hidden sm:ml-6 sm:flex sm:items-center">
                    {isEmpty(currentUser) && <LoginButton />}
                    
                    {!isEmpty(currentUser) &&
                    <button
                      type="button"
                      className="relative rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                    >
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button>}

                    {/* Profile dropdown */}
                    {!isEmpty(currentUser) &&
                    <Menu as="div" className="relative ml-3">
                      <div>
                        <Menu.Button className="relative flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2">
                          <span className="absolute -inset-1.5" />
                          <span className="sr-only">Open user menu</span>
                          {currentUser?.profilePicture ? 
                          <img className="h-8 w-8 rounded-full" src={currentUser?.profilePicture} alt="" />
                          : <UserIcon className="h-6 w-6 rounded-full text-gray-400" />}
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            <Menu.Item key="nav-user-name">
                              {({ active }) => (
                                <a
                                  href="#"
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-400'
                                  )}
                                >
                                  {/* Signed in as <br/> */}
                                  <span className="text-semibold text-gray-900">{`${currentUser?.name} (${currentUser?.mobile})`}</span>
                                </a>
                              )}
                            </Menu.Item>
                          </div>
                          
                          <div className="py-1">
                            {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <a
                                  href={item.href}
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700'
                                  )}
                                >
                                  {item.name}
                                </a>
                              )}
                            </Menu.Item>
                            ))}
                          </div>

                          <div className="py-1">
                            <Menu.Item key="logout">
                              {({ active }) => (
                                <a
                                  href="#"
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700 cursor-pointer'
                                  )}
                                  onClick={handleLogout}
                                >
                                  লগ আউট
                                </a>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>}
                  </div>
                  <div className="-mr-2 flex items-center sm:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2">
                      <span className="absolute -inset-0.5" />
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="sm:hidden">                
                {!isEmpty(currentUser) &&
                <div className="border-t border-gray-200 pb-3 pt-4">
                  <div className="flex items-center px-4">
                    <div className="flex-shrink-0">
                      {currentUser?.profilePicture ?
                      <img className="h-10 w-10 rounded-full" src={currentUser?.profilePicture} alt="" />
                      : <UserIcon className="h-10 w-10 rounded-full" />}
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium text-gray-800">{currentUser?.name}</div>
                      <div className="text-sm font-medium text-gray-500">{currentUser?.mobile}</div>
                    </div>
                    <button
                      type="button"
                      className="relative ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                    >
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="mt-3 space-y-1">
                    {userNavigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        href="#"
                        className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                        onClick={() => window.location.href = item.href}
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </div>
                  <div className="mt-3 space-y-1">
                    <Disclosure.Button
                      key="logout"
                      href="#"
                      className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                      onClick={handleLogout}
                    >
                      লগ আউট
                    </Disclosure.Button>
                  </div>
                </div>}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
    </>
  )
}
