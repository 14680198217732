import http from '../helpers/http';


export const createUserPreference = async (userId, payload) => {
  try {
    const { data } = await http.post(`/api/user-preferences/users/${userId}`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};
