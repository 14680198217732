import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
// import { ZoomMtg } from '@zoomus/websdk';
// import ZoomMtgEmbedded from '@zoom/meetingsdk/embedded';
// import { ZoomMtg } from "@zoom/meetingsdk";

import StackedLayout from '../../layouts/StackedLayout';
import { getLiveClass } from '../../services/liveClass';
import { getZoomSignature } from '../../services/zoom';

// ZoomMtg.preLoadWasm();
// ZoomMtg.prepareWebSDK();
// ZoomMtg.i18n.load('en-US');

// const client = ZoomMtgEmbedded.createClient();

export default function ZoomClassroomPage() {
  const navigate = useNavigate();
  const params = useParams();
  const liveClassId = params.id;
  const [liveClass, setLiveClass] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function _feetchData() {
      await fetchEvent();
      // const zoomMeetingSDK = document.getElementById('zmmtg-root');
      // zoomMeetingSDK.style.display = 'block';
      // ZoomMtg.setZoomJSLib('https://source.zoom.us/2.18.2/lib', '/av');
      // ZoomMtg.preLoadWasm();
      // ZoomMtg.prepareWebSDK();
      // ZoomMtg.i18n.load('en-US');
      // ZoomMtg.i18n.reload('en-US');
    };
    _feetchData();
  }, []);

  const fetchEvent = async () => {
    try {
      const _liveClass = await getLiveClass(liveClassId);
      setLiveClass(_liveClass);
    } catch (error) {
      toast.error(error.message);
      navigate(-1);
    }
  };

  // const joinMeeting = async () => {
  //   try {
  //     const zoomData = await getZoomSignature();
  //     ZoomMtg.init({
  //       leaveUrl: '', // https://example.com/thanks-for-joining
  //       success: (success) => {
  //         ZoomMtg.join({
  //           sdkKey: zoomData.clientId,
  //           signature: zoomData.signature, // role in SDK signature needs to be 0
  //           meetingNumber: event.meetingId,
  //           passWord: '',
  //           userName: 'test',
  //           success: (success) => {
  //             console.log(success)
  //           },
  //           error: (error) => {
  //             console.log(error)
  //           }
  //         })
  //       },
  //       error: (error) => {
  //         console.log(error)
  //       }
  //     })
  //   } catch (error) {
  //     toast.error(error.message);
  //   }
  // }

  return (
    <StackedLayout>
      {/* <div id="meetingSDKElement"></div>  */}
      <p>{liveClass?.name}</p> 
    </StackedLayout>
  )
};