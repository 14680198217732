import http from '../helpers/http';


export const getQuestionSolution = async (id) => {
  try {
    const { data } = await http.get(`/api/questions/${id}/solution`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};
