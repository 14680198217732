import http from '../helpers/http';


export const getVimeoDownloadLink = async (vimeoId) => {
  try {
    const { data } = await http.get(`/api/videos/vimeo/${vimeoId}`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getGotipathVideo = async (videoId) => {
  try {
    const { data } = await http.get(`/api/videos/gotipath/${videoId}`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};
