import { useEffect, useState } from 'react';
import CountDown from 'count-down-react';
import toast from 'react-hot-toast';

import { images } from '../../constants';
import { Button, SubmitButton } from '../../components/buttons';
import Loader from '../../components/Loader';
import ParticipantLoginForm from './components/ParticipantLoginForm';
import { getCompetitions, getCompetitionParticipant, getCompetitionExam } from '../../services/competition';
import { verifyCompetitionParticipantOtp } from '../../services/userOTP';
import { checkUserTakenExam } from '../../services/takenExam';


export default function SpaceExpolartionOlympiadPage() {
  const [competition, setCompetition] = useState(null);
  const [participant, setParticipant] = useState(null);
  const [openLoginForm, setOpenLoginForm] = useState(false);
  const [examStarted, setExamStarted] = useState(false);
  const [examTaken, setExamTaken] = useState(false);
  const [examOver, setExamOver] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function _fetchData() {
      const params = {
        name: 'Space Exploration Olympiad',
        season: 2024,
        status: 'active'
      };
      const competitions = await getCompetitions(params);
      if (competitions.length == 0) {
        window.location.href = '/';
      } else {
        const _competition = competitions[0];
        if (Date.now() >= new Date(_competition.endsAt)) {
          setExamOver(true);
        }
        setCompetition(_competition);

        const participantId = localStorage.getItem('participantId');
        if (participantId) {
          try {
            const _participant = await getCompetitionParticipant(participantId);
            if (_participant?.currentRound === _competition?.currentRound) {
              setParticipant(_participant);

              const takenExamQuery = {
                isCompetition: true,
                competition: _competition._id,
                competitionParticipant: participantId,
                competitionRound: _competition.currentRound
              };
              const takenExam = await checkUserTakenExam(takenExamQuery);
              if (takenExam?.isTaken) {
                setExamTaken(true);
              } else {
                if (Date.now() >= new Date(_competition.startsAt)) {
                  await handleStartExam(_competition, _participant);
                }
              }
            } else {
              localStorage.removeItem('participantId');
            }
          } catch (error) {
            toast.error(error.message);
            localStorage.removeItem('participantId');
          }
        }
      }
      setLoading(false);
    };
    _fetchData();
  }, []);

  const handleVerifyOtp = async (participantId, otp) => {
    try {
      const payload = { otp: otp };
      const _participant = await verifyCompetitionParticipantOtp(participantId, payload);
      setParticipant(_participant);
      setOpenLoginForm(false);
      localStorage.setItem('participantId', _participant?._id?.toString());
      
      const takenExamQuery = {
        isCompetition: true,
        competitionId: competition._id,
        participantId: _participant?._id,
        competitionRound: competition.currentRound
      };
      const takenExam = await checkUserTakenExam(takenExamQuery);
      if (takenExam?.isTaken) {
        setExamTaken(true);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleLogout = async () => {
    try {
      localStorage.removeItem('participantId');
      window.location.reload();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleStartExam = async (_competition, _participant) => {
    try {
      _competition = _competition || competition;
      _participant = _participant || participant;
      if (!_participant?._id) { return; }
      const data = await getCompetitionExam(_competition._id, _participant._id);
      const url = `/exam/${data?.examId}/competition/${_competition._id}/participant/${_participant._id}?round=${_competition.currentRound}`;
      window.open(url, '_blank', 'location=no');
    } catch (error) {
      toast.error(error.message);
    }
  };

  const CoundownRenderer = ({ days, hours, minutes, seconds }) => {
    if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
      setExamStarted(true);
      handleStartExam();
      return;
    }
    return (
      <div className="grid grid-cols-4 gap-x-6">
        <div>
          <p className="text-5xl">{days}</p>
          <p className="text-xl text-gray-600">Days</p>
        </div>
        <div>
          <p className="text-5xl">{hours}</p>
          <p className="text-xl text-gray-600">Hours</p>
        </div>
        <div>
          <p className="text-5xl">{minutes}</p>
          <p className="text-xl text-gray-600">Minutes</p>
        </div>
        <div>
          <p className="text-5xl">{seconds}</p>
          <p className="text-xl text-gray-600">Seconds</p>
        </div>
      </div>
    )
  };

  if (loading) {
    return (
      <Loader />
    )
  }
  
  return (
    <div className="bg-white">
      {openLoginForm &&
      <ParticipantLoginForm
        competition={competition}
        onVerify={handleVerifyOtp}
        onClose={() => setOpenLoginForm(false)}
      />}

      <div className="relative isolate px-6 pt-14 lg:px-8">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        <div className="sm:mx-auto pb-24">
          <div className="text-center">
            <div className="flex items-center text-center justify-center">
              <img width="400" src={competition?.logo} alt="Space Exploration Olympiad" />
            </div>

            <div>
              <h2 className="text-2xl text-purple-700">Exam round: {competition?.currentRound}</h2>

              {participant &&
              <div className="pt-10">
                <p>Participant's name: {participant?.name}</p>
                <p>Category: {participant?.category}</p>
              </div>}
              
              {!examOver && !examTaken && Date.now() < new Date(competition?.startsAt) &&
              <p className="pt-6 text-gray-500">Exam will start in</p>}
              
              {!examOver && !examTaken && Date.now() < new Date(competition?.startsAt) &&
              <div className="pt-6 flex justify-center items-center">
                <CountDown date={new Date(competition?.startsAt)} renderer={CoundownRenderer} />
              </div>}

              {!examOver && !participant && !openLoginForm &&
              <div className="pt-10">
                <SubmitButton action="Enter exam center" onClick={() => setOpenLoginForm(true)} />
              </div>}

              {participant && examTaken &&
              <p className="pt-6 text-indigo-600">Thank you for participating in the competition.</p>}

              {examOver && !examTaken &&
              <p className="pt-6 text-red-600">Sorry, the exam time is over!</p>}

              {participant && !examStarted && (examTaken || Date.now() < new Date(competition?.startsAt)) &&
              <div className="pt-10">
                <Button label="Exit exam center" onClick={handleLogout} />
              </div>}

              <div className="pt-12 flex items-center text-center justify-center">
                <img width="800" src={images.SpacePlympiadPartners} alt="" />
              </div>


            </div>

            {/* <div className="mt-24">
              <div>
                <img src={images.SpacePlympiadPartners} alt="" />
              </div>
            </div> */}

            {/* <div class="py-12 sm:py-16">
              <div class="mx-auto max-w-7xl px-6 lg:px-8">
                <h2 class="text-center text-lg font-semibold leading-8 text-gray-600">
                  Partners
                </h2>
                <div class="mx-auto mt-4 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
                  <img class="col-span-2 max-h-24 w-full object-contain lg:col-span-1" src={images.BifLogo} alt="" width="300"/>
                  <img class="col-span-2 max-h-24 w-full object-contain lg:col-span-1" src={images.SpaceCampLogo} alt="" width="300" />
                  <img class="col-span-2 max-h-24 w-full object-contain lg:col-span-1" src={images.AiuLogo} alt="" width="300" />
                  <img class="col-span-2 max-h-24 w-full object-contain sm:col-start-2 lg:col-span-1" src={images.BangleshMuseumLogo} alt="" width="300" />
                  <img class="col-span-2 col-start-2 max-h-24 w-full object-contain sm:col-start-auto lg:col-span-1" src={images.CreativeJuniorLogo} alt="" width="300" />
                </div>
              </div>
            </div> */}

          </div>
        </div>
        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
      </div>
    </div>
  )
};

