import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import toast from 'react-hot-toast';
import { UserIcon } from '@heroicons/react/24/outline';

import StackedLayout from '../../layouts/StackedLayout';
import { Input, UploadIcon,RadioGroupHorizontal, SelectDistrict } from '../../components/forms/fields';
import { SubmitButton, Button } from '../../components/buttons';
import { userActions } from '../../store/reducers/userReducers'
import { updateUser } from '../../services/user';
import { handleUploadPublicImage } from '../../utils';


const sidebarNavigation = [
  {name: 'প্রোফাইল', href: '/user/profile' },
  {name: 'পাসওয়ার্ড পরিবর্তন', href: '/user/change-password' },
];

const defaultUpdateStates = {
  name: false,
  district: false,
  gender: false,
  school: false,
  college: false,
  university: false
};

const genderLabels = {
  male: 'ছাত্র',
  female: 'ছাত্রী'
};

const genderOptions = [
  {name: 'ছাত্র', value: 'male'},
  {name: 'ছাত্রী', value: 'female'}
];

export default function UserProfilePage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.user);
  const currentUser = userState?.userInfo || null;
  const [name, setName] = useState(currentUser?.name);
  const [gender, setGender] = useState(currentUser?.gender);
  const [district, setDistrict] = useState(currentUser?.district);
  const [school, setSchool] = useState(currentUser?.school);
  const [college, setCollege] = useState(currentUser?.college);
  const [university, setUniversity] = useState(currentUser?.university);
  const [profilePicture, setProfilePicture] = useState(currentUser?.profilePicture);
  const [updateStates, setUpdateState] = useState(defaultUpdateStates)


  useEffect(() => {
    if (!currentUser) { return navigate('/auth/login'); }
  }, []);

  const onEdit = (event, fieldName) => {
    event.preventDefault();
    let _updateState = cloneDeep(updateStates);
    _updateState[fieldName] = true;
    setUpdateState(_updateState);
  };

  const onCancel = (event, fieldName) => {
    event.preventDefault();
    let _updateState = cloneDeep(updateStates);
    _updateState[fieldName] = false;
    setUpdateState(_updateState);
    if (fieldName === 'name') { setName(currentUser.name); }
    else if (fieldName === 'gender') { setGender(currentUser.gender); }
    else if (fieldName === 'district') { setDistrict(currentUser.district); }
    else if (fieldName === 'school') { setSchool(currentUser.school); }
    else if (fieldName === 'college') { setCollege(currentUser.college); }
    else if (fieldName === 'university') { setUniversity(currentUser.university); }
  };

  const onSave = async (event, fieldName, value) => {
    try {
      event.preventDefault();
      let payload = {};
      payload[fieldName] = value;
      await saveUser(payload);
      let _updateState = cloneDeep(updateStates);
      _updateState[fieldName] = false;
      setUpdateState(_updateState);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleProfilePicture = async (files) => {
    try {
      const imageUrl = await handleUploadPublicImage(files);
      setProfilePicture(imageUrl);
      const payload = {profilePicture: imageUrl};
      await saveUser(payload);
      return imageUrl;
    } catch (error) {
     toast.error(error.message); 
    }
  };

  const saveUser = async (payload) => {
    const updatedUser = await updateUser(currentUser._id, payload);
    dispatch(userActions.setUserInfo(updatedUser));
  };

  return (
    <StackedLayout
      sidebarNavigation={sidebarNavigation}
      currentSidebarNavigation="প্রোফাইল"
    >
      <div className="mb-10">
        <h2 className="text-base font-semibold leading-7 text-gray-900">প্রোফাইল</h2>
        <p className="mt-1 text-sm leading-6 text-gray-500">
          আপনার ব্যক্তিগত তথ্য আপডেট করুন
        </p>

        <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">মোবাইল</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-600">{currentUser?.mobile}</div>
            </dd>
          </div>
          <div className="pt-6 sm:flex items-center">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">নাম</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              {!updateStates.name && <div className="text-gray-900">{currentUser?.name}</div>}
              {updateStates.name &&
              <div className="w-full">
                <Input value={name} onChange={(event) => setName(event.target.value)}/>
                <div className="mt-4 space-x-4">
                  <SubmitButton action="সাবমিট" onClick={(event) => onSave(event, 'name', name)} />
                  <Button action="বাতিল" onClick={(event) => onCancel(event, 'name')} />
                </div>
              </div>}
              
              {!updateStates.name &&
              <button
                className="font-semibold text-green-600 hover:text-green-500"
                onClick={(event) => onEdit(event, 'name')}
              >
                পরিবর্তন
              </button>}
            </dd>
          </div>
          <div className="pt-6 sm:flex items-center">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">প্রোফাইল ফটো</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div>
                <UploadIcon actionName="পরিবর্তন" Icon={UserIcon} image={profilePicture} onUpload={handleProfilePicture} />
              </div>
            </dd>
          </div>
          <div className="pt-6 sm:flex items-center">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">শিক্ষার্থীর ধরন</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              {!updateStates.gender && <div className="text-gray-900">{genderLabels[currentUser?.gender]}</div>}
              {updateStates.gender &&
              <div className="w-full">
                <RadioGroupHorizontal value={gender} options={genderOptions} onChange={(event) => setGender(event.target.value)} />
                <div className="mt-4 space-x-4">
                  <SubmitButton action="সাবমিট" onClick={(event) => onSave(event, 'gender', gender)} />
                  <Button action="বাতিল" onClick={(event) => onCancel(event, 'gender')} />
                </div>
              </div>}
              
              {!updateStates.gender &&
              <button
                className="font-semibold text-green-600 hover:text-green-500"
                onClick={(event) => onEdit(event, 'gender')}
              >
                পরিবর্তন
              </button>}
            </dd>
          </div>
          <div className="pt-6 sm:flex items-center">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">বর্তমান জেলা</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              {!updateStates.district && <div className="text-gray-900">{currentUser?.district}</div>}
              {updateStates.district &&
              <div className="w-full sm:w-1/2">
                <SelectDistrict 
                  value={{label: district, value: district}}
                  onChange={(item) => setDistrict(item.value)}
                />
                <div className="mt-4 space-x-4">
                  <SubmitButton action="সাবমিট" onClick={(event) => onSave(event, 'district', district)} />
                  <Button action="বাতিল" onClick={(event) => onCancel(event, 'district')} />
                </div>
              </div>}
              
              {!updateStates.district &&
              <button
                className="font-semibold text-green-600 hover:text-green-500"
                onClick={(event) => onEdit(event, 'district')}
              >
                পরিবর্তন
              </button>}
            </dd>
          </div>
        </dl>
      </div>

      <div className="mb-10">
        <h2 className="text-base font-semibold leading-7 text-gray-900">শিক্ষা প্রতিষ্ঠান</h2>
        <p className="mt-1 text-sm leading-6 text-gray-500">আপনার শিক্ষা প্রতিষ্ঠানসমূহের নাম আপডেট করুন</p>

        <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
          <div className="pt-6 sm:flex items-center">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">বিদ্যালয়ের নাম</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              {!updateStates.school && <div className="text-gray-900">{currentUser?.school}</div>}
              {updateStates.school &&
              <div className="w-full">
                <Input value={school} onChange={(event) => setSchool(event.target.value)}/>
                <div className="mt-4 space-x-4">
                  <SubmitButton action="সাবমিট" onClick={(event) => onSave(event, 'school', school)} />
                  <Button action="বাতিল" onClick={(event) => onCancel(event, 'school')} />
                </div>
              </div>}
              
              {!updateStates.school &&
              <button
                className="font-semibold text-green-600 hover:text-green-500"
                onClick={(event) => onEdit(event, 'school')}
              >
                পরিবর্তন
              </button>}
            </dd>
          </div>
          <div className="pt-6 sm:flex items-center">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">কলেজের নাম</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              {!updateStates.college && <div className="text-gray-900">{currentUser?.college}</div>}
              {updateStates.college &&
              <div className="w-full">
                <Input value={college} onChange={(event) => setCollege(event.target.value)}/>
                <div className="mt-4 space-x-4">
                  <SubmitButton action="সাবমিট" onClick={(event) => onSave(event, 'college', college)} />
                  <Button action="বাতিল" onClick={(event) => onCancel(event, 'college')} />
                </div>
              </div>}
              
              {!updateStates.college &&
              <button
                className="font-semibold text-green-600 hover:text-green-500"
                onClick={(event) => onEdit(event, 'college')}
              >
                পরিবর্তন
              </button>}
            </dd>
          </div>
          <div className="pt-6 sm:flex items-center">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">বিশ্ববিদ্যালয়ের নাম</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              {!updateStates.university && <div className="text-gray-900">{currentUser?.university}</div>}
              {updateStates.university &&
              <div className="w-full">
                <Input value={university} onChange={(event) => setUniversity(event.target.value)}/>
                <div className="mt-4 space-x-4">
                  <SubmitButton action="সাবমিট" onClick={(event) => onSave(event, 'university', university)} />
                  <Button action="বাতিল" onClick={(event) => onCancel(event, 'university')} />
                </div>
              </div>}
              
              {!updateStates.university &&
              <button
                className="font-semibold text-green-600 hover:text-green-500"
                onClick={(event) => onEdit(event, 'university')}
              >
                পরিবর্তন
              </button>}
            </dd>
          </div>
        </dl>
      </div>
    </StackedLayout>
  )
};
