import { PlusCircleIcon } from '@heroicons/react/24/outline';


export default function PlusButton(props) {
  const onClick = props.onClick;
  const action = props.action || 'Add more';
  const disabled = props.disabled;

  return (
    <button
      disabled={disabled}    
      className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:opacity-30 disabled:cursor-not-allowed"
      onClick={onClick}
    >
      <PlusCircleIcon className="-ml-0.5 mr-0.5  h-5 w-5" aria-hidden="true" />
      {action}
    </button>
  )
}
