import http from '../helpers/http';


export const enrollFreeCourse = async (payload) => {
  try {
    const { data } = await http.post(`/api/enrolled-courses/free`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getUserEnrolledCourse = async (courseId) => {
  try {
    const { data } = await http.get(`/api/enrolled-courses/course/${courseId}/enrolled`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getEnrolledCourse = async (id) => {
  try {
    const { data } = await http.get(`/api/enrolled-courses/${id}`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getMyCourses = async () => {
  try {
    const { data } = await http.get(`/api/enrolled-courses/my-courses`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};
