import { useState, useEffect } from 'react';
import { RadioGroup } from '@headlessui/react';
import Latex from 'react-latex';
import { CheckCircleIcon } from '@heroicons/react/24/solid';

import { convertToBanglaNumber } from '../../../utils';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
};

const optionLabels = ['ক', 'খ', 'গ', 'ঘ', 'ঙ'];

export default function QuestionSection(props) {
  const { question, questionIndex, comprehension, onSelectAnswer, submittingAnswer } = props;
  const [selectedAnswer, setSelectedAnswer] = useState(null);

  useEffect(() => {
    setSelectedAnswer(null);
  }, [question, questionIndex]);

  const handleSelectAnswer = (answer) => {
    setSelectedAnswer(answer);
    onSelectAnswer(answer);
  };

  if (!question) {
    return (
      <div className="mt-4 text-gray-600">
        <p>এই বিষয়ের সকল প্রশ্নের উত্তর দেওয়া হয়েছে।</p>
      </div>
    )
  }

  return (
    <div className="mt-2 text-base">
      {comprehension &&
      <p className="pb-2 text-sm text-gray-600">
        <TextPanel text={comprehension} />
      </p>}
      <p className="flex flex-1 space-x-1">
        <span>{convertToBanglaNumber(questionIndex+1)}{'.'}</span>
        <TextPanel text={question.question} />
      </p>

      <RadioGroup value={selectedAnswer} onChange={handleSelectAnswer}>
        <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
        <div className="mt-2 space-y-2">
          {question.answers.map((answer, index) => (
            <RadioGroup.Option
              key={`question-index[${index}]`}
              value={answer}
              disabled={submittingAnswer}
              className={({ active }) =>
                classNames(
                  active ? 'border-green-600 ring-2 ring-green-600' : 'border-gray-300',
                  'relative block cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between'
                )
              }
            >
              {({ active, checked }) => (
                <>
                  <span className="flex w-full justify-between items-center">
                    <span className="flex flex-col">
                      <RadioGroup.Label as="span" className="font-medium text-gray-900">
                        <p className="flex flex-1 items-center space-x-1">
                          <span>{optionLabels[index]}{')'}</span>
                          <TextPanel text={answer.value} />
                        </p>
                      </RadioGroup.Label>
                    </span>
                    <span>
                      <RadioGroup.Description
                        as="span"
                        className="flex sm:ml-4 sm:mt-0 sm:flex-col sm:text-right"
                      >
                        <CheckCircleIcon
                          className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-green-600')}
                          aria-hidden="true"
                        />
                      </RadioGroup.Description>
                    </span>
                  </span>
                  <span
                    className={classNames(
                      active ? 'border' : 'border-2',
                      checked ? 'border-green-600' : 'border-transparent',
                      'pointer-events-none absolute -inset-px rounded-lg'
                    )}
                    aria-hidden="true"
                  />
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </div>
  )
};

function TextPanel(props) {
  const { text } = props;
  return (
    <span className="flex questions-answer-wrapper leading-6 text-gray-900 line-clamp-2">
      {text.includes("$$") ? 
      <Latex>{text}</Latex> : <span dangerouslySetInnerHTML={{ __html: text }}/>}
    </span>
  )
};