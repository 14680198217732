import { images } from '../../../constants';

const products = [
  {
    id: 1,
    name: 'এসএসসি ২০২৫ | পদার্থবিজ্ঞান ফুল কোর্স',
    color: 'Natural',
    price: '75',
    href: '#',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/home-page-04-trending-product-02.jpg',
    imageAlt: 'Hand stitched, orange leather long wallet.',
  },
  {
    id: 1,
    name: 'এসএসসি ২০২৫ | রসায়ন ফুল কোর্স',
    color: 'Natural',
    price: '75',
    href: '#',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/home-page-04-trending-product-02.jpg',
    imageAlt: 'Hand stitched, orange leather long wallet.',
  },
  {
    id: 1,
    name: 'এসএসসি ২০২৫ | উচ্চতর গণিত ফুল কোর্স',
    color: 'Natural',
    price: '75',
    href: '#',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/home-page-04-trending-product-02.jpg',
    imageAlt: 'Hand stitched, orange leather long wallet.',
  },
  {
    id: 1,
    name: 'এসএসসি ২০২৫ | জীববিজ্ঞান ফুল কোর্স',
    color: 'Natural',
    price: '75',
    href: '#',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/home-page-04-trending-product-02.jpg',
    imageAlt: 'Hand stitched, orange leather long wallet.',
  },
]

export default function CourseSection() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        <div className="md:flex md:items-center md:justify-between">
          <h2 className="text-2xl font-bold tracking-tight text-gray-900">এসএসসি কোর্সসমূহ</h2>
          <a href="#" className="hidden text-sm font-medium text-green-600 hover:text-green-500 md:block">
            সকল কোর্স দেখুন
            <span aria-hidden="true"> &rarr;</span>
          </a>
        </div>

        <div className="mt-6 grid grid-cols-2 gap-x-4 gap-y-10 sm:gap-x-6 md:grid-cols-4 md:gap-y-0 lg:gap-x-8">
          {products.map((product, index) => (
            <div key={`course-item[${index}]`} className="group relative">
              <div className="h-34 w-full overflow-hidden rounded-md bg-gray-200 group-hover:opacity-75">
                <img
                  src={images.CourseThumbnail}
                  alt=""
                  className="h-full w-full object-cover object-center"
                />
              </div>
              <h3 className="mt-4 text-sm text-gray-700">
                <a href={product.href}>
                  <span className="absolute inset-0" />
                  {product.name}
                </a>
              </h3>
              <p className="mt-1 text-sm text-gray-500">{product.color}</p>
              <p className="mt-1 text-sm font-medium text-gray-900">৳{product.price}</p>
            </div>
          ))}
        </div>

        <div className="mt-8 text-sm md:hidden">
          <a href="#" className="font-medium text-green-600 hover:text-green-500">
            Shop the collection
            <span aria-hidden="true"> &rarr;</span>
          </a>
        </div>
      </div>
    </div>
  )
}