export default function Input(props) {
  const label = props.label;
  const placeholder = props.placeholder || label;
  const type = props.type || 'text';
  const name= props.name;
  const value=props.value || '';
  const col = props?.col?.toString() || '3';
  const onChange= props.onChange;
  const required = props.required;
  const disabled = props.disabled;

  return (
    <div className={`sm:col-span-${col}`}>
      <label className="block text-sm font-medium leading-6 text-gray-900">
        {label}
      </label>
      <div className="mt-2">
        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-green-600 sm:max-w-md">
          <input
            type={type}
            name={name}
            value={value}
            placeholder={placeholder}
            required={required}
            disabled={disabled}
            className="block flex-1 w-full pl-2 border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 disabled:opacity-30"
            onChange={onChange}
          />
        </div>
      </div>
    </div>
  )
};
