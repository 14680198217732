import http from '../helpers/http';

export const getCourses = async (params) => {
  try {
    const config = { params };
    const { data } = await http.get(`/api/courses`, config);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getCourse = async (id) => {
  try {
    const { data } = await http.get(`/api/courses/${id}`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getBatches = async (id, params) => {
  try {
    params = params || {};
    const config = { params }
    const { data } = await http.get(`/api/courses/${id}/batches`, config);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};
