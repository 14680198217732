import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import StackedLayout from '../../layouts/StackedLayout';
import EmptyData from '../../components/EmptyData';
import { CourseCardCompact } from '../../components/courseCards';
import { getMyCourses } from '../../services/enrolledCourse';
import { convertToBanglaNumber } from '../../utils';
import Loader from '../../components/Loader';


const sidebarNavigation = [
  { name: 'গুরুগৃহ', href: '/learn/home' },
  { name: 'আমার কোর্সসমূহ', href: '/learn/my-courses' },
  { name: 'লাইভ ক্লাস রুটিন', href: '/learn/class-routine' },
  { name: 'ফ্রি কোর্সসমূহ', value: 'freeCourses' }
];

export default function MyCoursesPage() {
  const navigate = useNavigate();
  const userState = useSelector((state) => state.user);
  const currentUser = userState?.userInfo || null;
  const grade = currentUser?.preference?.grade;
  const [myCourses, setMyCourses] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!currentUser) {
      navigate('/auth/login');
    } else if (!currentUser.isOnboarded) {
      navigate('/onboarding/personal-info');
    } else {
      async function _fetchData() {
        try {
          const _myCourses = await getMyCourses();
          setMyCourses(_myCourses); 
          setLoading(false);
        } catch (error) {
          toast.error(error.message);
        }
      };
      _fetchData();
    }
  }, []);

  const handleChangeNavigation = (event, index) => {
    event.preventDefault();
    const navItem = sidebarNavigation[index];
    if (navItem?.value === 'freeCourses') {
      navigate(`/courses/programs/${grade}/free`);
    }
  };

  if (loading) {
    return (
      <Loader />
    )
  }
  return (
    <StackedLayout
      page="learn"
      sidebarNavigation={sidebarNavigation}
      currentSidebarNavigation="আমার কোর্সসমূহ"
      onNavigationChange={handleChangeNavigation}
    >
      <div className="space-y-8">
        {myCourses?.length > 0 && <CourseSection courseType="myCourses" courses={myCourses} />}
        {myCourses?.length === 0 &&
        <EmptyData
          title="আপনার কোন ভর্তিকৃত কোর্স নেই"
          subTitle="দেশসেরাগুরুদের সাথে সেরা প্রস্তুতি নিতে এখনি আপনার প্রয়োজনীয় কোর্সে ভর্তি হোন"
          showNewButton={true}
          label="সকল কোর্স দেখুন"
          onClick={() => navigate('/courses')}

        />}
      </div>
      
    </StackedLayout>
  )
};

function CourseSection(props) {
  const { courses } = props;
  const courseCount = courses.length;

  return (
    <div>
      <div className="border-b border-gray-200 pb-5">
        <div className="-ml-2 -mt-2 flex flex-wrap justify-between">
          <div className="flex mt-2 justify-start items-center">
            <h3 className="ml-2 text-base font-semibold leading-6 text-gray-900">ভর্তিকৃত কোর্সসমূহ</h3>
            <p className="ml-2 truncate text-sm text-gray-500">{`(${convertToBanglaNumber(courseCount)}টি কোর্স)`}</p>
          </div>

          <div className="mt-2">
            <a
              href="/courses"
              className="px-3 py-2 text-sm leading-6 text-green-600"
            >
              সকল কোর্স দেখুন <span aria-hidden="true">→</span>
            </a>
          </div>
        </div>
      </div>

      <div className="mt-6 lg:col-span-2 xl:col-span-3">
        <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8">
          {courses?.map((course, index) => (
            <CourseCardCompact key={`my-course-card-index[${index}]`} course={course} />
          ))}
        </div>
      </div>
    </div>
  )
};
