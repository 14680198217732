import http from '../helpers/http';


export const getSessions = async () => {
  try {
    const { data } = await http.get(`/api/app-settings/sessions`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};
