function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
};

export default function SubmitButton(props) {
  const onClick = props?.onClick;
  const action = props?.action || 'Add more';
  const disabled = props?.disabled;
  return (
    <button
      disabled={disabled}
      className={classNames(
        props?.className || '',
        'inline-flex justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-500 disabled:opacity-30 disabled:cursor-not-allowed'
      )}
      onClick={onClick}
    >
      {action}
    </button>
  )
}
