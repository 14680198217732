import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { orderBy } from 'lodash';
import { parseISO, format } from 'date-fns';
import { UserIcon, ClockIcon } from '@heroicons/react/24/outline';

import StackedLayout from '../../layouts/StackedLayout';
import { CourseCardGrid } from '../../components/courseCards';
import { getCourses } from '../../services/course';
import { getLiveClasses } from '../../services/liveClass';
import { convertToBanglaNumber, gradeLabelMapping, getBanglaTime } from '../../utils';


const sidebarNavigation = [
  { name: 'গুরুগৃহ', href: '/learn/home' },
  { name: 'আমার কোর্সসমূহ', href: '/learn/my-courses' },
  { name: 'লাইভ ক্লাস রুটিন', href: '/learn/class-routine' },
  { name: 'ফ্রি কোর্সসমূহ', value: 'freeCourses' }
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
};

export default function LearnPage() {
  const navigate = useNavigate();
  const userState = useSelector((state) => state.user);
  const currentUser = userState?.userInfo || null;
  const grade = currentUser?.preference?.grade;
  const [liveCourses, setLiveCourses] = useState([]);
  const [recordedCourses, setRecordedCourses] = useState([]);
  const [examCourses, setExamCourses] = useState([]);
  const [liveClasses, setLiveClasses] = useState([]);

  useEffect(() => {
    if (!currentUser) {
      navigate('/auth/login');
    } else if (!currentUser.isOnboarded) {
      navigate('/onboarding/personal-info');
    } else {
      async function _fetchData() {
        await fetchLiveClasses();

        const _liveCourses = await fetchCourses('live');
        setLiveCourses(_liveCourses);

        const _recordedCourses = await fetchCourses('recorded');
        setRecordedCourses(_recordedCourses);

        const _examCourses = await fetchCourses('exam');
        setExamCourses(_examCourses);
      };
      _fetchData();
    }
  }, []);

  const fetchLiveClasses = async () => {
    try {
      const params = {
        isToday: true,
        status: ['scheduled', 'open']
      };
      const _liveClasses = await getLiveClasses(params);
      setLiveClasses(orderBy(_liveClasses, 'startsAt', 'asc'));
    } catch (error) {
      toast.error(error.message);
    }
  };

  const fetchCourses = async (courseType) => {
    try {
      const params = {
        status: 'active',
        courseType: courseType,
        grade: grade
      };
      const courses = await getCourses(params);
      const orderedCourses = orderBy(courses, 'createdAt', 'desc');
      return orderedCourses;
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleChangeNavigation = (event, index) => {
    event.preventDefault();
    const navItem = sidebarNavigation[index];
    if (navItem?.value === 'freeCourses') {
      navigate(`/courses/programs/${grade}/free`);
    }
  };

  return (
    <StackedLayout
      page="learn"
      sidebarNavigation={sidebarNavigation}
      currentSidebarNavigation="গুরুগৃহ"
      onNavigationChange={handleChangeNavigation}
    >
      <div className="space-y-8">
        {liveClasses.length > 0 && <LiveClassSection liveClasses={liveClasses.slice(0, 3)} liveClassCount={liveClasses.length} />}
        {liveCourses?.length > 0 && <CourseSection courseType="live" grade={grade} courses={liveCourses.slice(0, 3)} courseCount={liveCourses.length} />}
        {recordedCourses?.length > 0 && <CourseSection courseType="recorded" grade={grade} courses={recordedCourses.slice(0, 3)} courseCount={recordedCourses.length} />}
        {examCourses?.length > 0 && <CourseSection courseType="exam" grade={grade} courses={examCourses.slice(0, 3)} courseCount={examCourses.length} />}
      </div>
      
    </StackedLayout>
  )
};

const titleMapping = {
  live: 'লাইভ ব্যাচ',
  recorded: 'রেকর্ডেড কোর্স',
  exam: 'মডেল টেস্ট'
};

function CourseSection(props) {
  const { courseType, grade,  courses, courseCount } = props;

  return (
    <div>
      <div className="border-b border-gray-200 pb-5">
        <div className="-ml-2 -mt-2 flex flex-wrap justify-between">
          <div className="flex mt-2 justify-start items-center">
            <h3 className="ml-2 text-base font-semibold leading-6 text-gray-900">{titleMapping[courseType]}</h3>
            <p className="ml-2 truncate text-sm text-gray-500">{`(${convertToBanglaNumber(courseCount)}টি কোর্স)`}</p>
          </div>
          
          {courseCount > 3 &&
          <div className="mt-2">
            <a
              href={`/courses/programs/${grade}?courseType=${courseType}`}
              className="px-3 py-2 text-sm leading-6 text-green-600"
            >
              সকল কোর্স দেখুন <span aria-hidden="true">→</span>
            </a>
          </div>}
        </div>
      </div>

      <div className="mx-2 mt-6">
        <div role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
          {courses.map((course, index) => (
            <CourseCardGrid key={`course-type[${courseType}]-card-index[${index}]`} course={course} />
          ))}
        </div>
      </div>
    </div>
  )
};

function LiveClassSection(props) {
  const { liveClasses, liveClassCount } = props;

  const onJoinClass = (event, liveClass) => {
    event.preventDefault();
    if (liveClass.status !== 'open') { return; }
    if (liveClass.liveClassPlatform === 'zoom' && liveClass.zoomMeetingLink) {
      window.open(liveClass.zoomMeetingLink);
    } else if (liveClass.liveClassPlatform === 'facebook' && liveClass.facebookGroup) {
      window.open(liveClass.facebookGroup);
    }
  };

  return (
    <div>
      <div className="border-b border-gray-200 pb-5">
        <div className="-ml-2 -mt-2 flex flex-wrap justify-between">
          <div className="flex mt-2 justify-start items-center">
            <h3 className="ml-2 text-base font-semibold leading-6 text-gray-900">আজকের লাইভ ক্লাস</h3>
            <p className="ml-2 truncate text-sm text-gray-500">{`(${convertToBanglaNumber(liveClassCount)}টি ক্লাস)`}</p>
          </div>
          
          <div className="mt-2">
            <a
              href={`/learn/class-routine`}
              className="px-3 py-2 text-sm leading-6 text-green-600"
            >
              ক্লাস রুটিন দেখুন <span aria-hidden="true">→</span>
            </a>
          </div>
        </div>
      </div>

      <ul role="list" className="mt-6 grid grid-cols-1 sm:grid-cols-2 mx-2 gap-6 overflow-hidden">
        {liveClasses.map((liveClass, index) => (
          <li
            key={`event-index[${index}]`}  
            className="m-1 col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white shadow cursor-pointer"
            onClick={(event) => onJoinClass(event, liveClass)}
          >
            <div className="flex space-x-6 py-2 sm:py-6 sm:mx-2 xl:static items-center">
              <div className="hidden sm:block">
                {liveClass?.instructor?.photo ?
                <img src={liveClass?.instructor?.photo} className="h-14 w-14 flex-none rounded-full" />
                :
                <UserIcon className="h-14 w-14 flex-none rounded-full text-gray-300" />
                }
              </div>

              <div className="flex-auto">
                <p className="text-purple-700">
                  {liveClass?.isFree ? 
                  <span className="text-indigo-600">ফ্রি ক্লাস | {gradeLabelMapping[liveClass?.grade]}</span> 
                  :
                  <span>{liveClass?.batch?.name || 'সকল ব্যাচ'} | {liveClass?.course?.name}</span>
                  }
                </p>
                <p className="mt-2 text-gray-900 xl:pr-0">
                  {`${liveClass?.subject?.name || 'টপিক'}: ${liveClass?.name}`}
                </p>
                <dl className="mt-2 flex flex-col text-gray-500 xl:flex-row">
                  <div className={classNames(
                    'flex items-center space-x-1',
                    liveClass.status === 'scheduled' && 'text-orange-400',
                    liveClass.status === 'open' && 'text-green-600',
                  )}>
                    <dt className="mt-0.5">
                      <span className="sr-only">Date</span>
                      <ClockIcon className="h-5 w-5" aria-hidden="true" />
                    </dt>
                    <dd>
                      {liveClass.status === 'open' && <span>ক্লাস চলছে</span>}
                      {liveClass.status === 'finished' && <span>ক্লাস শেষ</span>}
                      {liveClass.status === 'scheduled' &&
                      <time dateTime={format(parseISO(liveClass.startsAt), 'yyyy-MM-dd')}>
                        {getBanglaTime(liveClass.startsAt)}
                      </time>}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
};
