function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
};

export default function Button(props) {
  const onClick = props.onClick;
  const label = props.label || 'Cancel';
  const disabled = props.disabled;
  
  return (
    <button
      disabled={disabled}
      className={classNames(
        props?.className || '',
        'inline-flex justify-center items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50  disabled:opacity-30 disabled:cursor-not-allowed'
      )}
      onClick={onClick}
    >
      {label}
    </button>
  )
}
