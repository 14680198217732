import Logo from '../assets/images/bg-bn-logo-horizontal.png';
import Favicon from '../assets/images/bg-favicon-32.png';
import BGIcon from '../assets/images/bg-icon.png';
import CourseThumbnail from '../assets/images/course-default-thumbnail.jpg';
import HeroImage from '../assets/images/hero-image.jpg';
import LoginImage from '../assets/images/login-image.jpg';
import SSCIcon from '../assets/images/ssc-icon.png';
import HSCIcon from '../assets/images/hsc-icon.png';
import AdmissionIcon from '../assets/images/admission-icon.svg';
import JobIcon from '../assets/images/job-icon.svg';
import ScienceIcon from '../assets/images/science-icon.svg';
import CommerceIcon from '../assets/images/commerce-icon.svg';
import HumanitiesIcon from '../assets/images/humanities-icon.svg';
import Bkash from '../assets/images/bkash.svg';
import Nagad from '../assets/images/nagad.svg';
import CreditCard from '../assets/images/credit-card.svg';
import Visa from '../assets/images/visa.svg';
import MasterCard from '../assets/images/master.svg';
import Amex from '../assets/images/amex.svg';
import Note from '../assets/images/note.svg';
import Video from '../assets/images/video.svg';
import Youtube from '../assets/images/youtube.svg';
import Exam from '../assets/images/exam.svg';
import CreativeJuniorLogo from '../assets/images/spaceOlympiad/creative-juniors.png';
import BifLogo from '../assets/images/spaceOlympiad/bif.png';
import SpaceOlympiadLogo from '../assets/images/spaceOlympiad/space-olympiad-logo.png';
import SpaceCampLogo from '../assets/images/spaceOlympiad/space-innovation-camp.png';
import EsoftLogo from '../assets/images/spaceOlympiad/e-soft.png';
import AiuLogo from '../assets/images/spaceOlympiad/aiu.png';
import BangleshMuseumLogo from '../assets/images/spaceOlympiad/museum.png';
import SpacePlympiadPartners from '../assets/images/spaceOlympiad/space-olympiad-partners.png';

const images = {
  Logo,
  BGIcon,
  Favicon,
  CourseThumbnail,
  HeroImage,
  LoginImage,
  SSCIcon,
  HSCIcon,
  AdmissionIcon,
  JobIcon,
  ScienceIcon,
  CommerceIcon,
  HumanitiesIcon,
  Bkash,
  Nagad,
  CreditCard,
  Visa,
  MasterCard,
  Amex,
  Note,
  Video,
  Exam,
  Youtube,
  CreativeJuniorLogo,
  BifLogo,
  SpaceCampLogo,
  SpaceOlympiadLogo,
  EsoftLogo,
  AiuLogo,
  BangleshMuseumLogo,
  SpacePlympiadPartners

};

export default images;