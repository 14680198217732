import { CheckIcon } from '@heroicons/react/20/solid'

const features = [
  {
    name: 'লাইভ কোর্স',
    description: 'মোবাইল অ্যাপ ও ওয়েব অ্যাপের মাধ্যমে দেশসেরা গুরুদের সাথে সরাসরি লাইভ ক্লাস',
  },
  { 
    name: 'রেকর্ডেড কোর্স', 
    description: 'বেসিক থেকে অ্যাডভান্সড সকল টপিকের গাণিতিক সমস্যার সমাধানসহ পুর্নাঙ্গ সিলেবাসের রেকর্ডেড ক্লাস' 
  },
  {
    name: 'অনলাইন MCQ পরীক্ষা',
    description: 'মোবাইল অ্যাপ ও ওয়েব অ্যাপের মাধ্যমে MCQ পরীক্ষার সাথে সাথেই ব্যাখ্যাসহ সমাধান ও মেধা তালিকা',
  },
  {
    name: 'অনলাইন লিখিত পরীক্ষা',
    description: 'Sed rerum sunt dignissimos ullam. Iusto iure occaecati voluptate eligendi fugiat sequi.',
  },
  { 
    name: 'সাশ্রয়ী কোর্স ফি',
    description: 'সর্বোত্তম সেবা, সাশ্রয়ী মূল্যে, এককালীন ও বিভিন্ন মেয়াদের সাবস্ক্রিপশন প্যাকেজে'
  },
  { 
    name: 'এক প্লাটফর্ম', 
    description: 'দেশের সেরা শিক্ষক ও কোচিং এর অনলাইন ও অফলাইন কোর্সে ভর্তির সুযোগ' 
  },
]

export default function FeatureSection() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          <div>
            <h2 className="text-base font-semibold leading-7 text-green-600">
              আপনার জন্য যা দরকার
            </h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              সবই এখন এক প্লাটফর্মে
            </p>
            <p className="mt-6 text-base leading-7 text-gray-600">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste
              dolor cupiditate blanditiis ratione.
            </p>
          </div>
          <dl className="col-span-2 grid grid-cols-1 gap-x-8 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-9">
                <dt className="font-semibold text-gray-900">
                  <CheckIcon className="absolute left-0 top-1 h-5 w-5 text-green-500" aria-hidden="true" />
                  {feature.name}
                </dt>
                <dd className="mt-2">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}