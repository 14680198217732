import { PlusIcon } from '@heroicons/react/24/solid';


export default function PlusButtonSolid(props) {
  const onClick = props.onClick;
  const action = props.action || 'Add more';
  const disabled = props.disabled;

  return (
    <button
      type="button"
      disabled={disabled}
      className="inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
      onClick={onClick}
    >
      <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
      {action}
    </button>
  )
}
