import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { includes } from 'lodash';

import StackedLayout from '../../../layouts/StackedLayout';
import Loader from '../../../components/Loader';
import ContentAccordion from '../../../components/ContentAccordion';
import LiveClassInstruction from '../../../components/LiveClassInstruction';
import LiveClassRoutine from '../../../components/LiveClassRoutine';
import { getBatch } from '../../../services/batch';
import { getCourseContent } from '../../../services/courseContent';


const sidebarNavigation = [
  { name: 'লাইভ ক্লাস রুটিন', value: 'routine' },
  { name: 'রেকর্ডেড ক্লাস', value: 'lesson' },
  { name: 'MCQ পরীক্ষা', value: 'exam' },
  // { name: 'সৃজনশীল/লিখিত পরীক্ষা', value: 'cqExam' },
  { name: 'ডিজিটাল নোট', value: 'note' },
  { name: 'লাইভ ক্লাসের নির্দেশনা', value: 'liveInstruction' },
];

export default function EnrolledLiveCoursePage(props) {
  const { enrolledCourse, currentUser } = props;
  const courseId = enrolledCourse.courseId;
  const batchId = enrolledCourse.batchId;
  const [batch, setBatch] = useState(null);
  const [enrolledCourseData, setEnrolledCourseData] = useState({});
  const [selectedContentType, setSelectedContentType] = useState(sidebarNavigation[0]);
  const [topics, setTopics] = useState([]);
  const [courseContentId, setCourseContentId] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function _fetchData() {
      try {
        const _batch = await getBatch(batchId);
        setBatch(_batch);
        setEnrolledCourseData({
          courseName: _batch.course.name,
          courseType: _batch.course.courseType,
          thumbnail: _batch.course.thumbnail,
          batchName: _batch.name,
          expiresAt: enrolledCourse.expiresAt
        });
        setLoading(false);
      } catch (error) {
        toast.error(error.message);
      }
    };
    _fetchData();
  }, []);

  const fetchCourseContent = async (contentType) => {
    try {
      const params = {
        course: courseId,
        batch: batchId,
        contentType: contentType
      };
      const _contents = await getCourseContent(params);
      setTopics(_contents?.topics || []);
      setCourseContentId(_contents._id);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleSidebarNavigationChange = async (event, index) => {
    event.preventDefault();
    const _selectedContentType = sidebarNavigation[index];
    setSelectedContentType(_selectedContentType);

    const contentType = _selectedContentType.value;
    if (includes(['lesson', 'exam', 'cqExam', 'note'], contentType)) {
      await fetchCourseContent(contentType);
    } else {
      setTopics([]);
      setCourseContentId(null);
    }
  };

  if (loading) {
    return (
      <Loader />
    )
  }

  return (
    <StackedLayout
      page="course"
      enrolledCourseData={enrolledCourseData}
      sidebarNavigation={sidebarNavigation}
      currentSidebarNavigation={selectedContentType.name}
      onNavigationChange={handleSidebarNavigationChange}
    >

      {selectedContentType.value === 'routine' &&
      <LiveClassRoutine courseId={courseId} batchId={batchId} />}

      {selectedContentType.value === 'liveInstruction' &&
      <LiveClassInstruction currentUser={currentUser} batch={batch} />}

      {includes(['lesson', 'exam', 'note'], selectedContentType.value) &&
      <div>
        <h3 className="hidden sm:block ml-2 pb-4">{selectedContentType?.name}</h3>
        <ContentAccordion courseId={courseId} courseContentId={courseContentId} topics={topics} />
      </div>}

    </StackedLayout>
  )
};


