export default function Select(props) {
  const col = props?.col?.toString() || '3';
  const options = props.options;
  const label = props.label;
  const onChange = props.onChange;
  const name = props.name;
  const value = props.value;
  const disabled = props.disabled;

  const handleFieldChange = (event) => {
    const previousValue = value;
    const newValue = event.target.value;
    if (newValue === previousValue) { return; }
    onChange(event);
  };

  return (
    <div className={`sm:col-span-${col}`}>
      <label className="block text-sm font-medium leading-6 text-gray-900">
        {label}
      </label>
      <div className="mt-2">
        <select
          disabled={disabled}
          name={name}
          value={value}
          className="block px-2 w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
          onChange={handleFieldChange}
        >
          {options.map((_option) => (
            <option key={_option?.value || _option?._id || _option?.name || _option} value={_option?.value || _option?._id || _option?.name || _option}>{_option.name || _option?._id?.toString() || _option}</option>
          ))}
        </select>
      </div>
    </div>
  )
}

