import moment from 'moment';
import { round } from 'lodash';

import { uploadPublicImage } from '../services/upload';
import { images } from '../constants';

export const programIconMapping = {
  ssc: images.SSCIcon,
  hsc: images.HSCIcon,
  admission: images.AdmissionIcon,
  job: images.JobIcon
};

export const gradeLabelMapping = {
  ssc: 'এসএসসি একাডেমিক',
  hsc: 'এইচএসসি একাডেমিক',
  admission: 'বিশ্ববিদ্যালয় ভর্তি প্রস্তুতি',
  job: 'চাকরি প্রস্তুতি',
  skill: 'দক্ষতা উন্নয়ন',
};

export const groupLabelMapping = {
  science: 'বিজ্ঞান',
  commerce: 'ব্যবসায় শিক্ষা',
  humanities: 'মানবিক'
};

export const handleUploadPublicImage = async (files) => {
  try {
    const data = new FormData();
    if (files.length > 0) {
      data.append('image', files[0]);
      const responseData = await uploadPublicImage(data);
      return responseData.imageUrl;
    } else {
      throw new Error('No file attached');
    }
  } catch (error) {
    throw error;
  }
};

export const getDiscountedFee = (course) => {
  const originalFee = course.fee;
  const discount = course.discount || 0;
  let discountedFee = 0;
  if (course.discountType === 'flat') {
    discountedFee = originalFee - discount;
  } else {
    discountedFee = round(originalFee - ((originalFee * discount) / 100));
  }
  return discountedFee;
};

let numberMapping = {
  '0': '০',
  '1': '১',
  '2': '২',
  '3': '৩',
  '4': '৪',
  '5': '৫',
  '6': '৬',
  '7': '৭',
  '8': '৮',
  '9': '৯',
  '.': '.',
  ',': ',',
  ':': ':',
  '-': '-'
};

export const convertToBanglaNumber = (input) => {
  input = input || 0;
  input = input?.toLocaleString();
  let output = [];
  for (let i = 0; i < input.length; i++) {
    output.push(numberMapping[input[i]]);
  }
  return output.join('');
};

export const getNormalizedDateTime = (datetimeString, format) => {
  format = format || 'YYYY-MM-DD hh:mm A';
  return moment.utc(moment(datetimeString)).local().format(format);
};

const months = {
  January: 'জানুয়ারি',
  February: 'ফেব্রুয়ারি',
  March: 'মার্চ',
  April: 'এপ্রিল',
  May: 'মে',
  June: 'জুন',
  July: 'জুলাই',
  August: 'অগাস্ট',
  September: 'সেপ্টেম্বর',
  October: 'অক্টোবর',
  November: 'নভেম্বর',
  December: 'ডিসেম্বর'
};

const days = {
  Saturday: 'শনিবার',
  Sunday: 'রবিবার',
  Monday: 'সোমবার',
  Tuesday: 'মঙ্গলবার',
  Wednesday: 'বুধবার',
  Thursday: 'বৃহস্পতিবার',
  Friday: 'শুক্রবার',
};

export const getBanglaDate = (datetimeString) => {
  const date = moment.utc(moment(datetimeString)).local().format('DD');
  const month = moment.utc(moment(datetimeString)).local().format('MMMM');
  const year = moment.utc(moment(datetimeString)).local().format('YYYY');
  return `${convertToBanglaNumber(date)} ${months[month]}, ${convertToBanglaNumber(year)}`;
};

export const getBanglaDateWithDay = (datetimeString) => {
  const date = moment.utc(moment(datetimeString)).local().format('DD');
  const month = moment.utc(moment(datetimeString)).local().format('MMMM');
  const year = moment.utc(moment(datetimeString)).local().format('YYYY');
  const day = moment.utc(moment(datetimeString)).local().format('dddd');
  return `${convertToBanglaNumber(date)} ${months[month]}, ${convertToBanglaNumber(year)} (${days[day]})`;
};

export const getBanglaDay = (datetimeString) => {
  const day = moment.utc(moment(datetimeString)).local().format('dddd');
  return days[day];
};

export const getBanglaTime = (datetimeString) => {
  const meridium = moment.utc(moment(datetimeString)).local().format('A');
  const time = moment.utc(moment(datetimeString)).local().format('hh:mm');
  const hour = parseInt(moment.utc(moment(datetimeString)).local().format('hh'));

  let daytime = 'সকাল';
  if (meridium === 'PM') {
    if (hour === 12 || (hour >= 1 && hour < 3)) {
      daytime = 'দুপুর';
    } else if (hour >= 3 && hour < 6 ) {
      daytime = 'বিকাল';
    } else if (hour >= 6 && hour < 8) {
      daytime = 'সন্ধ্যা';
    } else {
      daytime = 'রাত';
    }
  }
  return `${daytime} ${convertToBanglaNumber(time)}`;
};
