import { useState } from 'react'
import { Sidepanel } from '../../../components/Sidepanel';
import { RadioGroup } from '@headlessui/react';
import { capitalize } from 'lodash';
import { getBanglaDate, getBanglaDateWithDay, getBanglaDay } from '../../../utils';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


export default function BatchList(props) {
  const { batches, course, onConfirm, onClose } = props;
  const [selectedBatch, setSelectedBatch] = useState(batches[0]);

  const onConfirmBatch = (event) => {
    onConfirm(selectedBatch);
    onClose();
  };

  return (
    <Sidepanel
      title="লাইভ ব্যাচের সময়সূচী"
      subTitle={`কোর্সের নামঃ ${course.name}`}
      action="নির্বাচিত ব্যাচে ভর্তি হোন"
      disabled={!selectedBatch}
      onSave={onConfirmBatch}
      onClose={onClose}
    >
      <RadioGroup value={selectedBatch} onChange={setSelectedBatch}>
        <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
        <div className="mx-20 mt-10 space-y-4">
          {batches.map((batch, index) => (
            <RadioGroup.Option
              key={`batch-index[${index}]`}
              value={batch}
              className={({ active }) =>
                classNames(
                  active ? 'border-green-600 ring-2 ring-green-600' : 'border-gray-300',
                  'relative block cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between'
                )
              }
            >
              {({ active, checked }) => (
                <>
                  <span className="flex items-center">
                    <span className="flex flex-col text-sm">
                      <RadioGroup.Label as="span" className="font-medium text-purple-700">
                        ব্যাচ: {batch.name}
                      </RadioGroup.Label>
                      <RadioGroup.Description as="span" className="text-gray-500 text-sm">
                        <span className="block sm:inline">
                          ক্লাসের সময়: {batch.batchTime}
                        </span>{' '}
                        <span className="hidden sm:mx-1 sm:inline" aria-hidden="true">
                        <br/>
                        </span>{' '}
                        <span className="block sm:inline">
                          লাইভ ক্লাস প্লাটফর্ম: {capitalize(batch.liveClassPlatform)}
                        </span>
                      </RadioGroup.Description>
                    </span>
                  </span>
                  <RadioGroup.Description
                    as="span"
                    className="mt-2 flex text-sm sm:ml-4 sm:mt-0 sm:flex-col sm:text-right"
                  >
                    <span className="font-medium text-gray-900">ক্লাস শুরু</span>
                    <span className="ml-1 text-gray-500 sm:ml-0">{getBanglaDate(batch.startDate)}</span>
                    <span className="ml-1 text-gray-500 sm:ml-0">({getBanglaDay(batch.startDate)})</span>
                  </RadioGroup.Description>
                  <span
                    className={classNames(
                      active ? 'border' : 'border-2',
                      checked ? 'border-green-600' : 'border-transparent',
                      'pointer-events-none absolute -inset-px rounded-lg'
                    )}
                    aria-hidden="true"
                  />
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>

    </Sidepanel>
  )
};