import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { ChevronRightIcon } from '@heroicons/react/24/outline';

import StackedLayout from '../../layouts/StackedLayout';
import { images } from '../../constants';
import { getSessions } from '../../services/appSettings';
import { Input } from '../../components/forms/fields';
import { SubmitButton } from '../../components/buttons';
import { getCurrentUser } from '../../services/user';
import { createUserPreference } from '../../services/userPreference'
import { userActions } from '../../store/reducers/userReducers';


const programs = [
  { name: 'এসএসসি একাডেমিক', value: 'ssc', icon: images.SSCIcon },
  { name: 'এইচএসসি একাডেমিক', value: 'hsc', icon: images.HSCIcon },
  { name: 'বিশ্ববিদ্যালয় ভর্তি প্রস্তুতি', value: 'admission', icon: images.AdmissionIcon },
  { name: 'চাকরি প্রস্তুতি', value: 'job', icon: images.JobIcon }
];

const groups = [
  { name: 'বিজ্ঞান', value: 'science', icon: images.ScienceIcon },
  { name: 'মানবিক', value: 'commerce', icon: images.CommerceIcon },
  { name: 'ব্যবসায় শিক্ষা', value: 'humanities', icon: images.HumanitiesIcon }
];

const titleMapping = {
  program: 'প্রোগ্রাম',
  session: 'সেশন',
  group: 'বিভাগ'
};

const sessionLabelMapping = {
  ssc: 'এসএসসি',
  hsc: 'এইচএসসি',
  admission: 'ভর্তি পরীক্ষা'
};

const institutionMapping = {
  ssc: 'বিদ্যালয়ের',
  hsc: 'কলেজের',
  admission: 'কলেজের',
  job: 'বিশ্ববিদ্যালয়ের'
};

const instituteTypeMapping = {
  ssc: 'school',
  hsc: 'college',
  admission: 'college',
  job: 'university'
};

export default function PreferencePage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.user);
  const currentUser = userState?.userInfo || null;
  const [section, setSection] = useState('program');
  const [grade, setGrade] = useState(null);
  const [session, setSession] = useState(null);
  const [group, setGroup] = useState(null);
  const [institute, setInstitute] = useState(null);
  const [activeSessions, setActiveSessions] = useState({});
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    async function _fetchData() {
      const _activeSessions = await getSessions();
      setActiveSessions(_activeSessions);
      if (currentUser?.preference?.grade) {
        const _existingInstitute = currentUser[instituteTypeMapping[grade]];
        setInstitute(_existingInstitute);
      }
    };
    _fetchData();
  }, [grade]);

  const handleSelectProgram = (program) => {
    const _grade = program.value;
    setGrade(_grade);
    setSection(_grade === 'job' ? 'institute' : 'session');
  };

  const handleSelectSession = (_session) => {
    setSession(_session);
    setSection('group');
  };

  const handleSelectGroup = (_group) => {
    setGroup(_group.value);
    setSection('institute');
  };

  const savePreferences = async (event) => {
    try {
      event.preventDefault();
      setUpdating(true);
      const payload = {
        grade,
        session,
        group,
        institute
      };
      await createUserPreference(currentUser._id, payload);
      const _currentUser = await getCurrentUser();
      dispatch(userActions.setUserInfo(_currentUser));
      navigate('/onboarding/welcome');
    } catch (error) {
      setUpdating(false);
      toast.error(error.message);
    }
  };

  return (
    <StackedLayout>
      <div className='flex flex-1 flex-col items-center pt-12'>
        <div className='flex flex-col items-center w-full sm:w-1/2	xl:w-1/3 2xl:w-1/3'>
            
            {section !== 'institute' &&
            <p className="text-2xl text-gray-500">
              <span className="text-gray-900 font-bold">
                {titleMapping[section]}
              </span> নির্বাচন করুন
            </p>}

            {section === 'institute' &&
            <p className="text-2xl text-gray-500">
              <span className="text-gray-900 font-bold">
                {institutionMapping[grade]}</span> নাম লিখুন
            </p>}
            
            <div className='my-3' />
            
            {section === 'program' && programs.map((program, index) => (
            <ProgramCard key={`program-index[${index}]`} program={program} onClick={handleSelectProgram} />
            ))}
            
            {section === 'session' && activeSessions[grade].map((session, index) => (
            <SessionCard key={`session-index[${index}]`} grade={grade} session={session} onClick={handleSelectSession} />
            ))}

            {section === 'group' && groups.map((_group, index) => (
            <GroupCard key={`group-index[${index}]`} group={_group} onClick={handleSelectGroup} />
            ))}

            {section === 'institute' &&
            <div className="w-full grid-cols-5 space-y-4">
              <Input
                label={`${institutionMapping[grade]} নাম`}
                placeholder={`${institutionMapping[grade]} নাম`}
                value={institute}
                onChange={(event) => setInstitute(event.target.value)}
              />
        
              <div className='my-3' />
        
              <SubmitButton
                  className="w-full"
                  disabled={updating || !institute}
                  action="শিখতে শুরু করুন"
                  onClick={savePreferences}
              />
            </div>
            }

        </div>
    </div>
    </StackedLayout>
  )
};

const programSubTitles = {
  ssc: 'রিভিশন, মডেল টেস্ট ও কলেজ ভর্তি',
  hsc: 'রেকর্ডেড, লাইভ কোর্স ও মডেল টেস্ট',
  admission: 'ইঞ্জিনিয়ারিং, মেডিকেল ও পাবলিক বিশ্ববিদ্যালয়',
  job: 'বিসিএস/প্রাইমারি শিক্ষক/অন্যান্য'
};

const ProgramCard = (props) => {
  const { program, onClick } = props;
  return (
    <div onClick={() => onClick(program)}
        className='flex flex-1 w-full bg-gray-50 rounded-lg items-center px-3 py-2 my-1.5 cursor-pointer'>
        <img className='w-14 h-14' src={program.icon} />
        
        <div className="flex w-full justify-between items-center">
          <div className="flex-inline ml-4">
            <p className="text-lg text-gray-900">{program.name}</p>
            <p className="text-sm text-gray-400">
              {programSubTitles[program.value]}
            </p>
          </div>
          <ChevronRightIcon className="w-6 h-6" />
        </div>
    </div>
  )
};

const SessionCard = (props) => {
  const { grade, session, onClick } = props;
  return (
    <div onClick={() => onClick(session)} className='flex flex-1 w-full bg-gray-50 rounded-lg items-center px-3 py-5 my-1.5 cursor-pointer'>
      <p className="text-lg text-gray-900 flex flex-1 ml-4">{sessionLabelMapping[grade]} {session}</p>
      <ChevronRightIcon className="w-6 h-6" />
    </div>
  )
};

const GroupCard = (props) => {
  const { group, onClick } = props;
  return (
    <div onClick={() => onClick(group)} className='flex flex-1 w-full bg-gray-50 rounded-lg items-center px-3 py-2 my-1.5 cursor-pointer'>
        <img className='w-14 h-14' src={group.icon} />
        <p className="text-lg text-gray-900 flex flex-1 ml-4">{group.name}</p>
        <ChevronRightIcon className="w-6 h-6" />
    </div>
  )
};
