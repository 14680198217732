import { useState } from "react";

export default function RadioGroupHorizontal(props) {
  const { label, name, options, onChange, value } = props;
  const col = props?. col?.toString() || '3';
  const [selectedOption, setSelectedOption] = useState(value || options[0].value);

  const handleChanges = (event) => {
    const _value = event.target.value;
    setSelectedOption(_value);
    onChange(event);
  };

  return (
    <div className={`col-span-${col}`}>
      <label className="block text-sm font-medium leading-6 text-gray-900">
        {label}
      </label>
      <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
          {options.map((option) => (
            <div key={option.value} className="flex items-center">
              <input
                id={option.value}
                name={name}
                value={option.value}
                type="radio"
                checked={option.value === selectedOption}
                className="h-4 w-4 border-gray-300 text-green-600 focus:ring-green-600"
                onChange={handleChanges}
              />
              <label htmlFor={option.value} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                {option.name}
              </label>
            </div>
          ))}
        </div>
    </div>
  )
};
