export default function LiveClassInstruction(props) {
  const { currentUser, batch, page } = props;

  return (
    <>
      {batch?.liveClassPlatform === 'zoom' &&
      <div>
        <div className={`mt-3 sm:mt-5 ${page === 'success' ? 'text-center' : ''}`}>
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            এই ব্যাচের সকল ক্লাস Zoom প্লাটফর্মে হবে।
          </h3>
        </div>
        <div className="mt-2">
          <p className="text-sm text-gray-900">
            লাইভ ক্লাসে যোগ দিতে অনুগ্রহ করে Zoom অ্যাপটি আপনার মোবাইল অথবা কম্পিউটারে ইন্সটল করে রাখুন
          </p>
        </div>
      </div>}

      {batch?.liveClassPlatform === 'facebook' &&
      <div>
        <div className={`mt-3 sm:mt-5 ${page === 'success' ? 'text-center' : ''}`}>
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            এই ব্যাচের সকল ক্লাস Facebook গ্রুপে হবে।
          </h3>
        </div>
        <div className="mt-2">
          <p className="text-sm text-gray-900">
            লাইভ ক্লাসে যোগ দিতে নিচের নির্দেশনাগুলো অনুসরণ করুন:
          </p>
          <p className="text-sm text-gray-500">
            ১। Facebbok গ্রুপের লিঙ্ক: <a className="text-indigo-600" href={batch.facebookGroup} target="_blank">{batch.facebookGroup}</a>
          </p>
          <div className="text-sm text-gray-500">
            ২। গ্রুপে যোগদানের সময় নিচের তথ্যগুলো সঠিকভাবে প্রদান করুন
            <ul className="ml-10">
              <li><b>নাম:</b> {currentUser.name}</li>
              <li><b>মোবাইল:</b> {currentUser.mobile}</li>
            </ul>
          </div>
          <p className="text-sm text-gray-500">
            ৩। আপনার সকল তথ্য যাচাই করে আপনাকে গ্রুপে যোগ করে নেওয়া হবে।
          </p>
          
        </div>
      </div>}
    </>
  )
};
