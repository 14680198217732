import {
  forEach,
  filter, 
  size, 
  cloneDeep, 
  keys, 
  indexOf, 
  findIndex
} from 'lodash';

export const getQuestionSet = (subjects, subjectArray) => {
  subjectArray = subjectArray || [];
  let examSubjects = [];
  examSubjects = size(subjectArray) === 0 ? subjects 
  : filter(subjects, function (subject) {
    return subjectArray.includes(subject.name);
  });

  let selectedSubjects = [];
  forEach(examSubjects, s => selectedSubjects.push({name: s.name, count: s.noOfQuestions, answered: 0}));

  let formattedSubjects = {
    subjects: examSubjects.reduce((obj, subject) => {
      obj[subject.name] = {
        _id: subject._id,
        noOfQuestions: subject.noOfQuestions,
        questionSet: subject.questionSet//_formatQuestions(subject.questions)
      };
      return obj;
    }, {})
  };

  let clonedSubjects = cloneDeep(formattedSubjects.subjects);
  let randomizedQuestionSet = {};

  keys(clonedSubjects).forEach(sub => {
    let selectedQuestions = [];
    let types = []; //values(clonedSubjects[sub].questionSet.type); //keys(clonedSubjects[sub].questions);
    forEach(clonedSubjects, subject => {
      forEach(subject.questionSet, qs => types.push(qs.type));
    });

    let remainingRequiredQuestions = clonedSubjects[sub].noOfQuestions;
    let remainingTypes = cloneDeep(types);

    while (remainingRequiredQuestions > 0) {
      let questionsRequiredPerType = Math.floor(remainingRequiredQuestions / size(remainingTypes)) > 0 ? Math.floor(remainingRequiredQuestions / size(remainingTypes)) : 1;
      let typeWithLessOrEqualQuestions = 0;

      if (remainingTypes.length > remainingRequiredQuestions) {
        remainingTypes = remainingTypes.sort(() => .5 - Math.random()).slice(0, remainingRequiredQuestions);
      }

      const tempRemainingTypes = cloneDeep(remainingTypes);
      forEach(tempRemainingTypes, (type, index) => {
        const typeIndex = findIndex(clonedSubjects[sub].questionSet, qs => qs.type === type);
        const questionsInType = clonedSubjects[sub].questionSet[typeIndex].questions.length;
        if (questionsInType <= questionsRequiredPerType) {
          typeWithLessOrEqualQuestions += 1;
          selectedQuestions = selectedQuestions.concat(clonedSubjects[sub].questionSet[typeIndex].questions.sort(() => .5 - Math.random()).slice(0, questionsInType));
          remainingRequiredQuestions -= questionsInType;
          remainingTypes.splice(index, 1);
        }
      });

      if (remainingRequiredQuestions > 0 && typeWithLessOrEqualQuestions === 0) {
        forEach(remainingTypes, remainingType => {
          const typeIndex = findIndex(clonedSubjects[sub].questionSet, qs => qs.type === remainingType);
          const randomlySelectedQuestions = clonedSubjects[sub].questionSet[typeIndex].questions.sort(() => .5 - Math.random()).slice(0, questionsRequiredPerType);
          selectedQuestions = selectedQuestions.concat(randomlySelectedQuestions);
          remainingRequiredQuestions -= questionsRequiredPerType;
          forEach(randomlySelectedQuestions, (question) => {
            clonedSubjects[sub].questionSet[typeIndex].questions.splice(indexOf(clonedSubjects[sub].questionSet[typeIndex].questions, question), 1);
          });
        })
      }
    }

    randomizedQuestionSet[sub] = {
      _id: clonedSubjects[sub]._id,
      noOfQuestions: clonedSubjects[sub].noOfQuestions,
      questions: [...selectedQuestions],
    }
  });
  return {questionSet: randomizedQuestionSet, subjects: selectedSubjects};
};
