const stats = [
  { name: 'শিক্ষার্থী', value: '৪০০,০০০+' },
  { name: 'শিক্ষক', value: '১০০+' },
  { name: 'কোর্স', value: '৫০০+' },
  { name: 'রেকর্ডেড ক্লাস', value: '৫,০০০+' },
]

export default function StatSection() {
  return (
    <div className="bg-green-700 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:max-w-none">
          <div className="text-center">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              সারাদেশের ৬৪ জেলার শিক্ষার্থীর আস্থা
            </h2>
            <p className="mt-4 text-lg leading-8 text-gray-300">
              সর্বোচ্চ মানের রেকর্ডেড ক্লাস, ইন্ট্যারেক্টিভ লাইভ ক্লাস ও দেশরা অনলাইন পরীক্ষার অভিজ্ঞতা
            </p>
          </div>
          <dl className="mt-16 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-4">
            {stats.map((stat, index) => (
              <div key={`stat-item[${index}]`} className="flex flex-col bg-white/5 p-8">
                <dt className="text-sm font-semibold leading-6 text-gray-300">{stat.name}</dt>
                <dd className="order-first text-3xl font-semibold tracking-tight text-white">{stat.value}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}