import http from '../helpers/http';


export const getMcqQuestionsSet = async (id, params) => {
  try {
    params = params || {};
    const config = { params }
    const { data } = await http.get(`/api/exams/${id}/question-set`, config);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getExam = async (id) => {
  try {
    const { data } = await http.get(`/api/exams/${id}`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};