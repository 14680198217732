import http from '../helpers/http';


export const createTakenExam = async (payload) => {
  try {
    const { data } = await http.post(`/api/taken-exams`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const createCompetitionTakenExam = async (payload) => {
  try {
    const { data } = await http.post(`/api/taken-exams/competition`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getTakenExam = async (id) => {
  try {
    const { data } = await http.get(`/api/taken-exams/${id}`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const checkUserTakenExam = async (params) => {
  try {
    params = params || {};
    const config = { params}
    const { data } = await http.get(`/api/taken-exams/user-check`, config);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};