import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';

import StackedLayout from '../../../layouts/StackedLayout';
import Loader from '../../../components/Loader';
import ContentAccordion from '../../../components/ContentAccordion';
import { getCourse } from '../../../services/course';
import { getCourseContent } from '../../../services/courseContent';


export default function EnrolledCoursePage(props) {
  const { enrolledCourse, currentUser } = props;
  const courseId = enrolledCourse.courseId;
  const [course, setCourse] = useState(null);
  const [enrolledCourseData, setEnrolledCourseData] = useState({});
  const [chapters, setChapters] = useState([]);
  const [selectedChapter, setSelectedChapter] = useState(null);
  const [topics, setTopics] = useState([]);
  const [courseContentId, setCourseContentId] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function _fetchData() {
      await fetchData();
    };
    _fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const _course = await getCourse(courseId);
      setCourse(_course);

      setEnrolledCourseData({
        courseName: _course.name,
        courseType: _course.courseType,
        thumbnail: _course.thumbnail,
        expiresAt: enrolledCourse.expiresAt
      });
      setChapters(_course.chapters);
      setSelectedChapter(_course.chapters[0]);
      await fetchContents(_course.chapters[0]._id);
      setLoading(false);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const fetchContents = async (chapterId) => {
    try {
      const params = {
        course: courseId,
        chapterId: chapterId
      };
      const courseContents = await getCourseContent(params);
      setTopics(courseContents?.topics || []);
      setCourseContentId(courseContents._id);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onNavigationChange = async (event, index) => {
    event.preventDefault();
    const _selectedChapter = chapters[index];
    setSelectedChapter(_selectedChapter);
    await fetchContents(_selectedChapter._id);

  };
  
  return (
    <StackedLayout
      loading={loading}
      page="course"
      enrolledCourseData={enrolledCourseData}
      sidebarNavigation={chapters}
      currentSidebarNavigation={selectedChapter?.name}
      onNavigationChange={onNavigationChange}
    >
      <div>
        <h3 className="hidden sm:block ml-2 pb-4">{selectedChapter?.name}</h3>
        <ContentAccordion courseId={courseId} topics={topics} courseContentId={courseContentId} />
      </div>
    </StackedLayout>
  )
};