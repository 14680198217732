import {CircleStackIcon} from '@heroicons/react/24/outline';
import { Button } from './buttons';


export default function EmptyData(props) {
  const title = props.title || 'No data';
  const subTitle = props.subTitle;
  const label = props.label || 'Create new';
  const showNewButton = props.showNewButton;
  const onClick = props.onClick;

  return (
    <div className="relative mt-3 block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
      <CircleStackIcon className="mx-auto h-12 w-12 text-gray-400" />
      <h3 className="mt-2 text-sm font-semibold text-gray-900">{title}</h3>
      <p className="mt-1 text-sm text-gray-500">{subTitle}</p>
      {showNewButton &&
      <div className="mt-6">
        <Button label={label} onClick={onClick} />
      </div>}
    </div>
  )
}

