import http from '../helpers/http';


export const getCourseContent = async (params) => {
  try {
    const config = { params: params || {} };
    const { data } = await http.get(`/api/course-contents`, config);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getTopic = async (id, params) => {
  try {
    const config = { params: params || {} };
    const { data } = await http.get(`/api/course-contents/${id}/topic`, config);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};
