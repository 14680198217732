import { includes } from 'lodash';
import {
  convertToBanglaNumber,
  getDiscountedFee,
  gradeLabelMapping,
  groupLabelMapping
} from '../../utils';


const courseTypelabels = {
  live: 'লাইভ',
  recorded: 'রেকর্ডেড',
  exam: 'মডেল টেস্ট'
};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
};

export default function CourseCardGrid(props) {
  const { course, isCompact, hideThumbnail } = props;
  const grade = course?.grade || course?.program?.grade;

  return (
    <div     
      className="flex flex-col pb-2 rounded-lg bg-white shadow cursor-pointer overflow-hidden"
      onClick={() => window.location.href = `/courses/${course._id}/details`}
    >
      <div>
        
        {!hideThumbnail &&
        <div className="w-full">
          <img src={course.thumbnail} alt="" />
        </div>}

        <div className="flex flex-1 flex-col mx-4 mt-2">
          <h3 className="text-sm font-medium text-purple-700 space-x-2">
            <span
              className={classNames(
                'px-2 rounded-full text-xs w-20 text-center',
                course.courseType === 'live' && 'bg-red-100 text-red-600',
                course.courseType === 'recorded' && 'bg-indigo-100 text-indigo-600',
                course.courseType === 'exam' && 'bg-green-100 text-green-600'
              )}
            >
              {courseTypelabels[course.courseType]}
            </span>
            <span>{course.name}</span>
          </h3>
          
          {!isCompact &&
          <p className="flex items-center space-x-1">
            <span>
              <img className="h-4 w-4" src={course?.institute?.icon} alt="" />
            </span>
            <span className="text-sm text-gray-900">{course?.institute?.name}</span>
          </p>}

          {!isCompact &&
          <div className="flex flex-1 my-4 flex-col space-y-4 justify-end">
            <p className="text-sm text-gray-500">
              {gradeLabelMapping[grade]} {!includes(['job', 'skill'], grade) && ` (${groupLabelMapping[course.group] || 'সকল'} বিভাগ)`}
            </p>
            <div className="flex items-center text-gray-500">
              {!course?.isFree && course?.fee > 0 ?
              <>
                {course?.discount > 0 ?
                <>
                  <div className="-mx-4 mr-1 rounded-r-full bg-red-100 text-red-600">
                    <p className="px-2">
                      {course?.discountType === 'flat' && '৳'}{convertToBanglaNumber(course?.discount)}{course?.discountType === 'percentage' && '%'} ছাড়!
                    </p>
                  </div>
                  <p className="text-base font-medium space-x-1">
                    <span className="text-green-600">৳{convertToBanglaNumber(getDiscountedFee(course))}</span>
                    <span className="mr-1 line-through decoration-red-600 text-gray-400">{convertToBanglaNumber(course.fee)}</span>
                  </p>
                </>
                :<p className="text-base font-medium text-green-600">৳{convertToBanglaNumber(course.fee)}</p>}
              </>
              : <p className="text-base font-medium text-red-700">ফ্রি</p>}
            </div>
          </div>}
        </div>
      </div>
    </div>
  )
};
