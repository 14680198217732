import { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';


export default function VideoPlayer(props) {
  const {
    uri,
    playerRef,
    onEnded
  } = props;
  const dimensions = useRefDimensions(playerRef);

  return (
    <ReactPlayer
      width={`${dimensions.width}px`}
      height={`${dimensions.width * (9 / 16)}px`}
      url={uri}
      onEnded={onEnded}
      controls
      playing
    />
  )
};

const useRefDimensions = (playerRef) => {
  const getDimensions = () => ({
    width: playerRef?.current?.offsetWidth,
    height: playerRef?.current?.offsetHeight
  })

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  useEffect(() => {
    const handleResize = () => {
      setDimensions(getDimensions())
    }

    if (playerRef.current) {
      setDimensions(getDimensions())
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [playerRef])

  return dimensions;
};
