import { DeviceUUID } from 'device-uuid';

import http from '../helpers/http';


export const verifyOTP = async (userId, payload) => {
  try {
    const uuid = new DeviceUUID().get();
    payload.deviceId = uuid;
    const { data } = await http.put(`/api/user-otp/users/${userId}/verify`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const resendOTP = async (userId, payload) => {
  try {
    const { data } = await http.put(`/api/user-otp/users/${userId}/resend`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const sendForgotPasswordOTP = async (payload) => {
  try {
    const uuid = new DeviceUUID().get();
    payload.deviceId = uuid;
    const { data } = await http.post(`/api/user-otp/forgot-password`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const sendCompetitionParticipantOtp = async (competionId, payload) => {
  try {
    const uuid = new DeviceUUID().get();
    payload.deviceId = uuid;
    const { data } = await http.post(`/api/user-otp/competitions/${competionId}`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const verifyCompetitionParticipantOtp = async (participantId, payload) => {
  try {
    const uuid = new DeviceUUID().get();
    payload.deviceId = uuid;
    const { data } = await http.put(`/api/user-otp/participants/${participantId}/verify`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};
