import Footer from '../../../layouts/Footer';
import { images } from '../../../constants';
import WarningAlert from '../../../components/WarningAlert';


export default function AuthLayout(props) {
  const { title, subTitle } = props;
  return (
    <>
      {/* <div>
        <WarningAlert message="This is site is under contruction. We appreciate your interests on visiting us. All the feature will be live soon..." />
      </div> */}
      <div>
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="mb-4 sm:mx-auto sm:w-full sm:max-w-sm">
            <img
              className="mx-auto max-h-10 cursor-pointer"
              src={images.Logo}
              alt="Your Company"
              onClick={() => window.location.href = '/'}
            />
            <div className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              <p>{title}</p>
            </div>
            <div className="my-4 text-center text-sm font-medium text-gray-400">
              <p>{subTitle}</p>
            </div>
          </div>

          {props.children}
        </div>
      </div>

      <Footer />
    </>
  )
};
