import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import StackedLayout from '../../layouts/StackedLayout';
import LiveClassRoutine from '../../components/LiveClassRoutine';


const sidebarNavigation = [
  { name: 'গুরুগৃহ', href: '/learn/home' },
  { name: 'আমার কোর্সসমূহ', href: '/learn/my-courses' },
  { name: 'লাইভ ক্লাস রুটিন', href: '/learn/class-routine' },
  { name: 'ফ্রি কোর্সসমূহ', value: 'freeCourses' }
];

export default function LiveClassRoutinePage() {
  const navigate = useNavigate();
  const userState = useSelector((state) => state.user);
  const currentUser = userState?.userInfo || null;
  const grade = currentUser?.preference?.grade;

  useEffect(() => {
    if (!currentUser) {
      navigate('/auth/login');
    } else if (!currentUser.isOnboarded) {
      navigate('/onboarding/personal-info');
    }
  }, []);

  const handleChangeNavigation = (event, index) => {
    event.preventDefault();
    const navItem = sidebarNavigation[index];
    if (navItem?.value === 'freeCourses') {
      navigate(`/courses/programs/${grade}/free`);
    }

  };

  return (
    <StackedLayout
      page="learn"
      sidebarNavigation={sidebarNavigation}
      currentSidebarNavigation="লাইভ ক্লাস রুটিন"
      onNavigationChange={handleChangeNavigation}
    >
      <LiveClassRoutine />

    </StackedLayout>
  )
};