import Select from 'react-select';


export default function MultiSelect(props) {
  const {
    col,
    label,
    name,
    value,
    disabled,
    options,
    onChange,
    controlShouldRenderValue
  } = props;

  return (
    <div className={`sm:col-span-${col?.toString() || '3'}`}>
      <label className="block text-sm font-medium leading-6 text-gray-900">
        {label}
      </label>
      <div className="mt-2">
        <Select
          disabled={disabled}
          isMulti={true}
          name={name}
          value={value}
          options={options}
          onChange={onChange}
          controlShouldRenderValue={controlShouldRenderValue}
          className="block pr-2 w-full rounded-md border-0 text-gray-900 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:max-w-xs sm:text-sm sm:leading-6"
        />
      </div>
    </div>
  )
}

