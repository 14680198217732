function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
};

export default function SubjectTabs(props) {
  const { subjects, subjectIndex, onChange } = props;
  return (
    <div>
      <div className="sm:hidden">
        <select
          name="subjects"
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-green-500 focus:outline-none focus:ring-green-500 sm:text-sm"
          value={subjects[subjectIndex].name}
          onChange={(event) => onChange(event.target.value)}
        >
          {subjects.map((subject) => (
            <option
              key={subject?.name}
              value={subject.name}
            >
              {subject?.name} ({subject.answered || '0'}/{subject.count})
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {subjects?.map((subject, index) => (
              <a
                key={subject?.name}
                href="#"
                className={classNames(
                  subjectIndex === index
                    ? 'border-green-500 text-green-600'
                    : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
                  'flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium'
                )}
                aria-current={subject?.current ? 'page' : undefined}
                onClick={(event) => onChange(subject.name)}
              >
                {subject?.name}
                {subject?.count ? (
                  <span
                    className={classNames(
                      subjectIndex === index ? 'bg-green-100 text-green-600' : 'bg-gray-100 text-gray-900',
                      'ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block'
                    )}
                  >
                    {subject.answered || '0'}/{subject?.count}
                  </span>
                ) : null}
              </a>
            ))}
          </nav>
        </div>
      </div>
    </div>
  )
};
