import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { PhotoIcon } from '@heroicons/react/24/solid';

import PlaceholderLoader from '../../../components/PlaceholderLoader';


export default function UploadWithDragDrop(props) {
  const label = props.label;
  const actionName = props.actionName;
  const col = props?.col?.toString() || 'full';
  const onUpload = props.onUpload;
  const [image, setImage] = useState(props.image);
  const [isUploading, setIsUploading] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
    'image/*': ['.jpg', '.jpeg', '.png']
    },
    multiple: false,
    onDrop: async (acceptedFiles) => {
      setIsUploading(true);
      const _image = await onUpload(acceptedFiles);
      setImage(_image);
      setIsUploading(false);
    },
  });

  return (
    <div className={`col-span-${col}`} {...getRootProps()}>
      <label className="block text-sm font-medium leading-6 text-gray-900">
        {label}
      </label>
      <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
        {isUploading && <PlaceholderLoader />}

        {!isUploading &&
        <div className="text-center">
          {!image && <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />}
          {image && <img className="mx-auto h-32" src={image} aria-hidden="true" />}
          <div className="mt-4 flex text-sm leading-6 text-gray-600 justify-center">
            <input {...getInputProps()} />
            <p>
              <span className="relative cursor-pointer rounded-md bg-white font-semibold text-green-600  hover:text-green-500">
                {actionName}
              </span>
              &nbsp;or drag and drop files here
            </p>
          </div>
          <p className="text-xs leading-5 text-gray-600">PNG, JPG, JPEG up to 2MB</p>
        </div>}
      </div>
    </div>
  )
}