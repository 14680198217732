import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { orderBy } from 'lodash';
import {
  UserGroupIcon,
  ClockIcon,
  VideoCameraIcon,
  DocumentCheckIcon,
  ClipboardDocumentListIcon,
  CheckCircleIcon,
  AcademicCapIcon,
  PhoneIcon
} from '@heroicons/react/24/outline';
import { PlayIcon, ArrowDownTrayIcon } from '@heroicons/react/24/solid';

import StackedLayout from '../../../layouts/StackedLayout';
import Loader from '../../../components/Loader';
import ConfirmModal from '../../../components/modals/ConfirmModal';
import VideoPlayerPopup from '../../../components/VideoPlayerPopup';
import Badge from '../../../components/Badge';
import BatchList from './BatchList';
import { images } from '../../../constants';
import { getCourse, getBatches } from '../../../services/course';
import { enrollFreeCourse } from '../../../services/enrolledCourse';
import { getDownloadableLink } from '../../../services/download';
import {
  convertToBanglaNumber,
  getDiscountedFee,
  gradeLabelMapping,
  groupLabelMapping,
  getBanglaDateWithDay
} from '../../../utils';

const courseTypeMapping = {
  live: 'লাইভ কোর্স',
  recorded: 'রেকর্ডেড কোর্স',
  exam: 'মডেল টেস্ট'
};


export default function CourseDetailsPage(props) {
  const navigate = useNavigate();
  const { courseId, currentUser} = props;
  const [course, setCourse] = useState(null);
  const [batches, setBatches] = useState([]);
  const [showBatchList, setShowBatchList] = useState(false);
  const [openFreeCourseModal, setOpenFreeCourseModal] = useState(false);
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [showCallNowModal, setShowCallNowModal] = useState(false); 
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    async function _fetchData() {
      await fetchCourse();
      setLoading(false);
    };
    _fetchData();
  }, []);

  const fetchCourse = async () => {
    try {
      const _course = await getCourse(courseId);
      setCourse(_course);

      if (_course.courseType === 'live') {
        await fetchBatches();
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const fetchBatches = async () => {
    try {
      const params = { status: 'active' };
      const _batches = await getBatches(courseId, params);
      setBatches(orderBy(_batches, 'startDate', 'desc'));
    } catch (error) {
      toast.error(error.message);
    }
  };

  const goToCheckout = async (_selectedBatch) => {
    try {
      if (!course.isFree) {
        setShowCallNowModal(true);
      } else {
        if (!currentUser && !course.isFree) { return navigate('/auth/login'); }
        if (course.isFree) {
          setSelectedBatch(_selectedBatch);
          setOpenFreeCourseModal(true);
        } else if (course.courseType === 'live') {
          navigate(`/checkout/course/${courseId}/batch/${_selectedBatch._id}`);
        } else {
          navigate(`/checkout/course/${courseId}`);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleEnrollFreeCourse = async (event) => {
    try {
      event.preventDefault();
      if (!currentUser) { return navigate('/auth/login'); }
      if (!course.isFree) { throw new Error('This course is not free.') }
      let payload = {
        courseId: courseId,
        courseType: course.courseType,
        instituteId: course.institute._id
      };
      if (course.courseType === 'live') {
        payload.batchId = selectedBatch._id;
      }
      const enrolledCourse = await enrollFreeCourse(payload);
      navigate(`/payment-success/course/${enrolledCourse._id}`);
    } catch (error) {
      toast.error(error.message);
      setSelectedBatch(null);
      setOpenFreeCourseModal(false);
    }
  };

  const handleCallNow = () => {
    window.open(`tel:${process.env.REACT_APP_HELPLINE}`);
    setShowCallNowModal(false);
  };

  return (
    <StackedLayout loading={loading}>
      {openFreeCourseModal &&
      <ConfirmModal
        title="ফ্রি কোর্স"
        description={`আপনি কি এই কোর্সে ভর্তি নিশ্চিত করতে চান? এই কোর্সটি আপনার জন্য একদম ফ্রি আর মেয়ারদ থাকবে ${course.courseType === 'live' ? 'কোর্স শেষ না হওয়া পর্যন্ত' : `${convertToBanglaNumber(course.validity)} বছর`}।`}
        actionName="ভর্তি নিশ্চিত করুন"
        onConfirm={handleEnrollFreeCourse}
        onCancel={() => {
          setSelectedBatch(null);
          setOpenFreeCourseModal(false);
        }}
      />}
      {showCallNowModal &&
      <ConfirmModal
        title="কোর্সটিতে ভর্তি হতে চান?"
        description={`এই কোর্সটিতে ভর্তি হতে আমাদের সাথে সরাসরি যোগাযোগ করুন। আমাদের যোগাযোগ করতে নিচের বাটনে ক্লিক করুন।`}
        actionName="কল করুন"
        onConfirm={handleCallNow}
        onCancel={() => {
          setSelectedBatch(null);
          setShowCallNowModal(false);
        }}
      />}
      {showBatchList &&
      <BatchList
        course={course}
        batches={batches}
        onConfirm={goToCheckout}
        onClose={() => setShowBatchList(false)}
      />}
      <div className="mx-auto sm:py-6 lg:max-w-7xl">
        {/* Product */}
        <div className="lg:grid lg:grid-cols-7 lg:grid-rows-1 lg:gap-x-8 lg:gap-y-10 xl:gap-x-16">
          {/* Product image */}
          <div className="lg:col-span-4 lg:row-end-1">
            <div className="aspect-h-3 aspect-w-4 overflow-hidden rounded-lg bg-gray-100">
              <img src={course?.thumbnail} alt="" className="object-cover object-center" />
            </div>
          </div>

          {/* Product details */}
          <div className="mx-auto mt-14 max-w-2xl sm:mt-16 lg:col-span-3 lg:row-span-2 lg:row-end-2 lg:mt-0 lg:max-w-none">
            <div className="flex flex-col-reverse">
              <div>
                <h1 className="text-2xl font-bold tracking-tight text-purple-700 sm:text-3xl">{course?.name}</h1>
                
                <div className="flex items-center mt-2 text-sm text-gray-500 space-x-1">
                  <p>{courseTypeMapping[course?.courseType]}</p>
                  <div className="h-1 w-1 rounded-full bg-gray-500" />
                  <p>{gradeLabelMapping[course?.program?.grade]}{course?.group ? ` (${groupLabelMapping[course?.group]})` : ''}</p>
                </div>

                <div className="flex items-center mt-2 text-sm text-gray-500 space-x-1">
                  <img className="h-6 w-6" src={course?.institute?.icon} alt="" />
                  <p>{course?.institute?.name}</p>
                </div>
              </div>
            </div>

            <div className="mt-3">
              {(course?.isFree || !course?.fee) &&
              <p className="text-3xl tracking-tight text-gray-900">ফ্রি কোর্স</p>}
              {!course?.isFree && course?.discount > 0 &&
              <div className="flex space-x-2 items-center">
                <Badge color="red" label={`${course?.discountType === 'flat' ? '৳' : ''}${convertToBanglaNumber(course?.discount)}${course?.discountType === 'percentage' ? '%' : ''} ছাড়!`} />
                <p className="text-2xl tracking-tight space-x-1">
                  <span className="text-gray-900">৳{convertToBanglaNumber(getDiscountedFee(course))}</span>
                  <span className="mr-1 line-through decoration-red-600 text-gray-400">{convertToBanglaNumber(course.fee)}</span>
                </p>
              </div>}
              
              {!course?.isFree && course?.fee > 0 && (!course?.discount || course?.discount <= 0) &&
              <p className="text-3xl tracking-tight text-gray-900">৳{convertToBanglaNumber(course.fee)}</p>}
            </div>

            <HighlightSection course={course} />
            <StatSection course={course} batch={batches[0]} />

            <div className="mt-6 grid grid-cols-full gap-x-6 gap-y-4">
              {/* {course?.courseType === 'live' &&
              <p className="text-sm text-center text-gray-600 underline">
                চলতি {convertToBanglaNumber(batches.length)}টি ব্যাচে ভর্তি চলছে
              </p>} */}

              <button
                type="button"
                className="flex w-full items-center justify-center rounded-md border border-transparent bg-green-600 px-8 py-3 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                onClick={() => {
                  if (course?.courseType === 'live') {
                    setShowBatchList(true);
                  } else {
                    goToCheckout();
                  }
                }}
              >
                কোর্সটিতে ভর্তি হোন
              </button>

              <div className="flex flex-1 w-full items-center space-x-1 text-sm text-purple-500">
                <PhoneIcon className="h-4 w-4 text-purple-600" />
                <a href={`tel:${process.env.REACT_APP_HELPLINE}`}>ভর্তি সংক্রান্ত সহযোগিতার জন্য কল করুন {convertToBanglaNumber(process.env.REACT_APP_HELPLINE)} এই নাম্বারে</a>
              </div>
            </div>

            {course?.contents?.length > 0 && <ContentSection contents={course?.contents} />}
            {course?.reasons?.length > 0 && <ReasonSection reasons={course?.reasons} />}
            {course?.subjects?.length > 0 && <SubjectSection subjects={course?.subjects} />}
            {/* <InstituteSection institute={course?.institute} /> */}
            {course?.instructors?.length > 0 && <InstructorSection instructors={course?.instructors} />} 
          </div>

          <div className="mx-auto mt-16 w-full max-w-2xl lg:col-span-4 lg:mt-0 lg:max-w-none">
            <div className="border-t border-gray-200 pt-10">
              <h3 className="text-sm font-medium text-gray-900">কোর্স সিলেবাস</h3>
              <ul role="list" className="divide-y text-sm">
                {course?.chapters?.map((chapter, index) => (
                  <li key={`course-chapter-index[${index}]`} className="py-4">
                    <p>{chapter.name}</p>
                    <p className="mt-2 space-x-4 space-y-2">
                      {chapter.liveCount > 0 && <Badge color="red" label={`লাইভ ক্লাস ${convertToBanglaNumber(chapter.liveCount)}টি`} />}
                      {chapter.recordCount > 0 && <Badge color="green" label={`রেকর্ডেড ক্লাস ${convertToBanglaNumber(chapter.recordCount)}টি`} />}
                      {chapter.examCount > 0 && <Badge color="indigo" label={`অনলাইন পরীক্ষা ${convertToBanglaNumber(chapter.examCount)}টি`} />}
                      {chapter.noteCount > 0 && <Badge color="yellow" label={`ডিজিটাল নোট ${convertToBanglaNumber(chapter.noteCount)}টি`} />}
                    </p>
                  </li>
                ))}
              </ul>
            </div>

            <CommunitySection />
          </div>
        </div>
      </div>
    </StackedLayout>
  )
};

function HighlightSection(props) {
  const { course } = props;

  return (
    <div className="mt-6">
      <div className="prose prose-sm text-gray-500">
        <ul role="list" className="flex justify-center items-center space-x-12 text-sm">
          {course.liveCount > 0 &&
          <li className="space-y-2">
            <div className="flex justify-center items-center">
              <VideoCameraIcon className="h-8 w-8 rounded-full text-center text-red-600" />
            </div>
            <div className="flex justify-center items-center text-center">
              <p>লাইভ ক্লাস<br/><span className="font-semibold">{convertToBanglaNumber(course.liveCount)}</span>টি</p>
            </div>
          </li>}

          {course.recordCount > 0 &&
          <li className="space-y-2">
            <div className="flex justify-center items-center">
              <VideoCameraIcon className="h-8 w-8 rounded-full text-center text-green-600" />
            </div>
            <div className="flex justify-center items-center text-center">
              <p>রেকর্ডেড ক্লাস<br/><span className="font-semibold">{convertToBanglaNumber(course.recordCount)}</span>টি</p>
            </div>
          </li>}

          {course.examCount > 0 &&
          <li className="space-y-2">
            <div className="flex justify-center items-center">
              <DocumentCheckIcon className="h-8 w-8 rounded-full text-center text-indigo-600" />
            </div>
            <div className="flex justify-center items-center text-center">
              <p>অনলাইন পরীক্ষা<br/><span className="font-semibold">{convertToBanglaNumber(course.examCount)}</span>টি</p>
            </div>
          </li>}

          {course.noteCount > 0 &&
          <li className="space-y-2">
            <div className="flex justify-center items-center">
              <ClipboardDocumentListIcon className="h-8 w-8 rounded-full text-center text-yellow-600" />
            </div>
            <div className="flex justify-center items-center text-center">
              <p>ডিজিটাল নোট<br/><span className="font-semibold">{convertToBanglaNumber(course.noteCount)}</span>টি</p>
            </div>
          </li>}
        </ul>
      </div>
    </div>
  )
};

function StatSection(props) {
  const { course, batch } = props;

  return (
    <div className="mt-6">
      <div className="prose prose-sm text-gray-500">
        <ul role="list" className="text-sm space-y-2">
          {course?.courseType === 'live' &&
          <li className="flex flex-1 w-full items-center space-x-2">
            <ClockIcon className="h-6 w-6 text-gray-600" />
            <p>
              কোর্সটির সময়কাল আনুমানিক{' '}
              <span className="font-semibold">
                {convertToBanglaNumber(course?.duration)}
              </span>
              {' '}মাস
            </p>
          </li>}

          {course?.courseType !== 'live' &&
          <li className="flex flex-1 w-full items-center space-x-2">
            <ClockIcon className="h-6 w-6 text-gray-600" />
            <p>
              কোর্সটির মেয়াদ{' '}
              <span className="font-semibold">
                {convertToBanglaNumber(course?.validity)}
              </span>
              {' '}বছর
            </p>
          </li>}

          <li className="flex flex-1 w-full items-center space-x-2">
            <UserGroupIcon className="h-6 w-6 text-gray-600" />
            <p>
              কোর্সটি করছেন{' '}
              <span className="font-semibold">
                {convertToBanglaNumber((course?.enrolledCount || 0) + 101)}
              </span>
              {' '}জন শিক্ষার্থী
            </p>
          </li>

          {course?.courseType === 'live' &&
          <li className="flex flex-1 w-full items-center space-x-2">
            <AcademicCapIcon className="h-6 w-6 text-gray-600" />
            <p>
              নতুন ব্যাচের ক্লাস শুরু{' '}
              <span className="font-medium text-indigo-600">
                {getBanglaDateWithDay(batch?.startDate)}
              </span>
            </p>
          </li>}
        </ul>
      </div>
    </div>
  )
};

function InstituteSection(props) {
  const { institute } = props;

  return (
    <div className="mt-10 border-t border-gray-200 pt-10">
      <h3 className="text-sm font-medium text-gray-900">কোর্স পরিচালনায়</h3>      
      <div className="flex flex-1 mt-2 space-x-1 items-center">
        <img className="h-8 w-8" src={institute?.icon} alt="" />
        <p className="text-lg">{institute?.name}</p>
        <p className="text-lg">{institute?.description}</p>
      </div>
    </div>
  )
};

function SubjectSection(props) {
  const { subjects } = props;

  return (
    <div className="mt-10 border-t border-gray-200 pt-10">
      <h3 className="text-sm font-medium text-gray-900">বিষয়সমূহ</h3>
      <div className="mt-2 space-x-1 space-y-2 items-center">
        {subjects?.map((subject, index) => (
          <Badge key={`course-subject-index[${index}]`} label={subject?.name} />

        ))}
      </div>
    </div>
  )
};

function InstructorSection(props) {
  const { instructors } = props;

  return (
    <div className="mt-10 border-t border-gray-200 pt-10">
      <h3 className="text-sm font-medium text-gray-900">শিক্ষকমণ্ডলী</h3>
      <ul className="mt-2">
        {instructors?.map((instructor, index) => (
          <li key={`instructor-index[${index}]`} className="flex sm:span-col-full items-center justify-between gap-x-6 py-2">
            <div className="flex min-w-0 gap-x-4">
              <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={instructor?.photo} alt="" />
              <div className="min-w-0 flex-auto">
                <p className="text-sm font-semibold leading-6 text-gray-900">{instructor?.name}</p>
                <p className="mt-1 truncate text-xs leading-5 text-gray-500">{instructor?.designation}</p>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
};

function ReasonSection(props) {
  const { reasons } = props;

  return (
    <div className="mt-10 border-t border-gray-200 pt-10">
      <h3 className="text-sm font-medium text-gray-900">এই কোর্সটি কেন করবেন?</h3>
      <div className="mt-2 space-y-2 items-center">
        {reasons?.map((reason, index) => (
          <div key={`course-reason-index[${index}]`} className="flex space-x-1 justify-start items-center text-sm text-gray-600">
            <CheckCircleIcon className="h-4 w-4 text-green-600" />
            <p>{reason}</p>
          </div>
        ))}
      </div>
    </div>
  )
};

function ContentSection(props) {
  const { contents } = props;
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [openVidePlayer, setOpenVideoPlayer] = useState(false);

  const handleEbookPreview = async (event, pdfLink) => {
    try {
      event.preventDefault();
      const responseData = await getDownloadableLink({ privateLink: pdfLink });
      window.open(responseData.publicLink);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handlePlayVIdeo = async(lesson) => {
    setSelectedLesson(lesson);
    setOpenVideoPlayer(true);
  };

  return (
    <>
    {openVidePlayer &&
    <VideoPlayerPopup
      lesson={selectedLesson}
      onClose={() => {
        setOpenVideoPlayer(false);
        setSelectedLesson(null);
      }}
    />
    }
      <div className="mt-10 border-t border-gray-200 pt-10">
        <h3 className="text-sm font-medium text-gray-900">নমুনা কন্টেন্ট</h3>
        <div className="mt-2 space-y-2 items-center">
          {contents?.map((content, index) => (
            <div key={`content-index[${index}]`} className="flex justify-between items-center">
              <div className="flex space-x-1 justify-start items-center text-sm text-gray-600">
                <img className="h-4 w-4" src={content?.contentType === 'lesson' ? images.Video : images.Note} alt="" />
                <p>{content.contentType === 'lesson' ? content?.lesson?.title : content?.note?.name}</p>            
              </div>
              <div className='p-2 cursor-pointer'>
                {content.contentType === 'lesson' ?
                <PlayIcon className="h-4 w-4 text-indigo-700" onClick={() => handlePlayVIdeo(content.lesson)} />
                :
                <ArrowDownTrayIcon className="h-4 w-4 text-indigo-700" onClick={(event) => handleEbookPreview(event, content.note.pdf)} />}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
};

function CommunitySection() {
  return (
    <div className="mt-10 border-t border-gray-200 pt-10">
      <h3 className="text-sm font-medium text-gray-900">কমিউনিটিতে যোগ দিন</h3>
      <ul role="list" className="mt-4 flex items-center space-x-6">
        <li>
          <a href="https://youtube.com/bidyaguru" target="_blank" className="flex h-6 w-6 items-center justify-center text-gray-400 hover:text-gray-500">
            <span className="sr-only">Share on Youtube</span>
            
            <svg className="h-5 w-5" fill="#cd201f" viewBox="0 0 16 16">
              <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408z"/>
            </svg>
          </a>
        </li>
        <li>
          <a href="https://facebook.com/bidyaguru.bangladesh" target="_blank" className="flex h-6 w-6 items-center justify-center text-gray-400 hover:text-gray-500">
            <span className="sr-only">Share on Facebook</span>
            <svg className="h-5 w-5" aria-hidden="true" fill="#316FF6" viewBox="0 0 20 20">
              <path
                fillRule="evenodd"
                d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                clipRule="evenodd"
              />
            </svg>
          </a>
        </li>
        <li>
          <a href="https://instagram.com.bidyaguru" target="_blank" className="flex h-6 w-6 items-center justify-center text-gray-400 hover:text-gray-500">
            <span className="sr-only">Share on Instagram</span>
            <svg className="h-6 w-6" aria-hidden="true" fill="#d62976" viewBox="0 0 24 24">
              <path
                fillRule="evenodd"
                d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                clipRule="evenodd"
              />
            </svg>
          </a>
        </li>
        <li>
          <a href="https://twitter.com/bidyaguru" target="_blank" className="flex h-6 w-6 items-center justify-center text-gray-400 hover:text-gray-500">
            <span className="sr-only">Share on Twitter</span>
            <svg className="h-5 w-5" aria-hidden="true" fill="#1da1f2" viewBox="0 0 20 20">
              <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
            </svg>
          </a>
        </li>
      </ul>
    </div>
  )
};
