import http from '../helpers/http';


export const getBatch = async (id) => {
  try {
    const { data } = await http.get(`/api/batches/${id}`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};
