import { Routes, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

import './App.css';
import PageNotFound from './pages/PageNotFound';
import HomePage from './pages/home/HomePage';
import TermsPage from './pages/TemrsPage'
import LoginPage from './pages/auth/LoginPage';
import RegistrationPage from './pages/auth/RegistrationPage';
import OTPPage from './pages/auth/OTPPage';
import PersonalInfoPage from './pages/onboarding/PersonalInfoPage';
import PreferencePage from './pages/onboarding/PreferancePage';
import WelcomePage from './pages/onboarding/WelcomePage';
import CoursesPage from './pages/courses/CoursesPage';
import CourseDetailsPage from './pages/courses/CourseDetailsPage';
import CourseCheckoutPage from './pages/courses/CourseCheckoutPage';
import LearnPage from './pages/learn/LearnPage';
import ZoomClassroomPage from './pages/classrooms/ZoomClassroom';
import CourseEnrollSuccessPage from './pages/courses/CourseEnrollSuccessPage';
import MyCoursesPage from './pages/learn/MyCoursesPage';
import LiveClassRoutinePage from './pages/learn/LiveClassRoutinePage';
import MCQExamPage from './pages/exam/MCQExamPage';
import ExamResultPage from './pages/exam/ExamResultPage';
import EbookViewPage from './pages/ebook/EbookViewPage';
import UserProfilePage from './pages/settings/UserProfilePage';
import PasswordChangePage from './pages/settings/PasswordChangePage';
import ForgotPasswordPage from './pages/auth/ForgotPasswordPage';
import ResetPasswordPage from './pages/auth/ResetPasswordPage';
import PlaylistPage from './pages/lesson/PlaylistPage';
import SpaceExpolartionOlympiadPage from './pages/competitions/SpaceExpolationOlympiad';
import CompetitionExamPage from './pages/exam/CompetitionExamPage';


function App() {
  return (
    <>
      <div id="zmmtg-root"></div>
      <div className="app font-gilroy">      
        <Routes>
          <Route index path="/" element={<HomePage />} />
          <Route index path="/terms" element={<TermsPage />} />
          
          <Route path="/auth">
            <Route index path="login" element={<LoginPage />} />
            <Route index path="register" element={<RegistrationPage />} />
            <Route index path="otp" element={<OTPPage />} />
            <Route index path="forgot-password" element={<ForgotPasswordPage />} />
            <Route index path="reset-password" element={<ResetPasswordPage />} />
          </Route>

          <Route path="/onboarding">
            <Route index path="personal-info" element={<PersonalInfoPage />} />
            <Route index path="preferences" element={<PreferencePage />} />
            <Route index path="welcome" element={<WelcomePage />} />
          </Route>
          
          <Route path="/courses">
            <Route index element={<CoursesPage />} />
            <Route index path="programs/:grade" element={<CoursesPage />} />
            <Route index path="programs/:grade/free" element={<CoursesPage isFree={true} />} />
            <Route index path=":id/details" element={<CourseDetailsPage />} />
          </Route>

          <Route path="/checkout">
            <Route index path="course/:id" element={<CourseCheckoutPage />} />
            <Route index path="course/:id/batch/:batchId" element={<CourseCheckoutPage />} />
          </Route>

          <Route path="/payment-success">
            <Route index path="course/:id" element={<CourseEnrollSuccessPage />} />
          </Route>
          
          <Route path="/learn">
            <Route index path="home" element={<LearnPage />} />
            <Route index path="my-courses" element={<MyCoursesPage />} />
            <Route index path="class-routine" element={<LiveClassRoutinePage />} />
          </Route>

          <Route path="/classroom">
            <Route index path="zoom/:id" element={<ZoomClassroomPage />} />
          </Route>

          <Route path="/exam">
            <Route index path=":id/course/:courseId" element={<MCQExamPage />} />
            <Route index path=":id/result" element={<ExamResultPage />} />
            <Route index path=":id/competition/:competitionId/participant/:participantId" element={<CompetitionExamPage />} />
          </Route>

          <Route path="/lessons">
            <Route index path=":courseContentId/topics/:topicIndex/course/:courseId" element={<PlaylistPage />} />
          </Route>

          <Route path="/ebooks">
            <Route index path=":id/view" element={<EbookViewPage />} />
          </Route>

          <Route path="/user">
            <Route index path="profile" element={<UserProfilePage />} />
            <Route index path="change-password" element={<PasswordChangePage />} />
          </Route>

          <Route path="/competitions">
            <Route index path="spaceolympiad" element={<SpaceExpolartionOlympiadPage />} />
          </Route>

          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <Toaster position="bottom-right" toastOptions={{duration: 3000}} />
      </div>
    </>
  );
}

export default App;
