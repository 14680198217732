import { useSelector } from 'react-redux';
import Header from './Header';
import Footer from './Footer';
import Sidebar from './Sidebar';
import Loader from '../components/Loader';
import WarningAlert from '../components/WarningAlert';



export default function StackedLayout(props) {
  const userState = useSelector((state) => state.user);
  const currentUser = userState?.userInfo;
  const {
    loading,
    page,
    hideHeader,
    showFooter,
    enrolledCourseData,
    sidebarNavigation,
    currentSidebarNavigation,
    onNavigationChange
  } = props;

  if (loading) {
    return (
      <Loader />
    )
  }

  return (
    <div>
      {/* <div>
        <WarningAlert message="This is site is under contruction. We appreciate your interests on visiting us. All the feature will be live soon..." />
      </div> */}

      {!hideHeader && <Header currentUser={currentUser} />}

      <div className={`${!hideHeader ? 'my-20' : 'my-4'} mx-auto max-w-7xl lg:flex lg:gap-x-16 lg:px-8`}>
        {sidebarNavigation &&
        <Sidebar
          page={page}
          enrolledCourseData={enrolledCourseData}
          currentUser={currentUser}
          navigation={sidebarNavigation}
          currentNavigation={currentSidebarNavigation}
          onNavigationChange={onNavigationChange}
        />}

        <main className="px-4 sm:py-4 sm:px-6 lg:flex-auto lg:px-0 overflow-x-hidden">
          <div className="mx-auto max-w-2xl space-y-4 lg:mx-0 lg:max-w-none">
            {props.children}
          </div>
        </main>
      </div>

      {showFooter && <Footer />}
    </div>
  )
}