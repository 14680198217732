import { configureStore } from "@reduxjs/toolkit";
import { userReducer } from "./reducers/userReducers";
import { getCurrentUser } from "../services/user";


const _getCurrentUser = async () => {
  try {
    const data = await getCurrentUser();
    return data;
  } catch {
    return null;
  }
};

const userInfoFromStorage = await _getCurrentUser();

const initialState = {
  user: { userInfo: userInfoFromStorage }
};

const store = configureStore({
  reducer: {
    user: userReducer,
  },
  preloadedState: initialState,
});

export default store;
