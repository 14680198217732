import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

import StackedLayout from '../../layouts/StackedLayout';
import { Input, SelectDistrict, RadioGroupHorizontal } from '../../components/forms/fields';
import { SubmitButton } from '../../components/buttons';
import { updateUser, getCurrentUser } from '../../services/user';
import { userActions } from '../../store/reducers/userReducers';


const genderOptions = [
  {name: 'ছাত্র', value: 'male'},
  {name: 'ছাত্রী', value: 'female'},
];

export default function PersonalInfoPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.user);
  const currentUser = userState?.userInfo || null;
  const [name, setName] = useState(currentUser?.name);
  const [gender, setGender] = useState(genderOptions[0].value);
  const [dob, setDob] = useState(null);
  const [district, setDistrict] = useState(null);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    if (!currentUser) {
      navigate('/auth/login');
    } else if (currentUser?.isOnboarded) {
      navigate('/learn/home');
    }
  });

  const handleUpdateProfile = async (event) => {
    try {
      event.preventDefault();
      setUpdating(true);
      const payload = {
        name,
        gender,
        district,
        dob
      };
      await updateUser(currentUser._id, payload);
      const _currentUser = await getCurrentUser();
      dispatch(userActions.setUserInfo(_currentUser));
      navigate('/onboarding/preferences');
    } catch (error) {
      toast.error(error.message);
      setUpdating(false);
    }
  };

  const isDisabled = () => {
    return updating || !name || !gender || !district || !dob;
  };

  return (
    <StackedLayout>
      <div className="flex flex-1 flex-col justify-center px-6 lg:px-8">
        <div>
          <div className="mt-10 text-center text-2xl font-bold leading-7 tracking-tight text-gray-900">
            <p>হ্যালো, {currentUser?.name}</p>
          </div>
          <div className="my-4 text-center text-sm font-medium text-gray-400">
            <p>আপনার সম্পর্কে আরও কিছু তথ্য দিন</p>
          </div>
        </div>
        
        <div className="flex justify-center items-center">
          <div className="w-full sm:w-1/3 grid-cols-5 space-y-4">
            <Input label="আপনার নাম" value={name} onClick={(event) => setName(event.target.value)} />
            <Input type="date" label="জন্ম তারিখ" value={dob} onChange={(event) => setDob(event.target.value)} />
            <SelectDistrict
              label="আপনার জেলার নাম"
              placeholder="জেলার নাম লিখুন"
              value={{label: district, value: district}}
              onChange={(item) => setDistrict(item.value)}
            />
            <RadioGroupHorizontal 
              label="আপনি একজন"
              options={genderOptions}
              onChange={(event) => setGender(event.target.value)}
            />

            <SubmitButton
              disabled={isDisabled()}
              className="mt-8 w-full"
              action="আপডেট করুন"
              onClick={handleUpdateProfile}
            />
          </div>
        </div>
      </div>
    </StackedLayout>
  )
};