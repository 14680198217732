// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/SutonnyMJ/SutonnyMJ.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/SulekhaT/SulekhaT.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./fonts/VillageII/VILLIEB_.TTF", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./fonts/Gilroy/Gilroy-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'SutonnyMJ';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
  }
  
  @font-face {
    font-family: 'SulekhaT';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
  }
  
  @font-face {
    font-family: 'VillageII';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype');
  }
  
  @font-face {
    font-family: 'Roboto Condensed', sans-serif;
    src: url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,300i,400,400i,700&display=swap') format('truetype');
  }
  
  @font-face {
    font-family: 'Gilroy';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('truetype');
  }`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,+DAA8D;EAChE;;EAEA;IACE,uBAAuB;IACvB,+DAA4D;EAC9D;;EAEA;IACE,wBAAwB;IACxB,+DAA6D;EAC/D;;EAEA;IACE,2CAA2C;IAC3C,0HAA0H;EAC5H;;EAEA;IACE,qBAAqB;IACrB,+DAAgE;EAClE","sourcesContent":["@font-face {\n    font-family: 'SutonnyMJ';\n    src: url('./fonts/SutonnyMJ/SutonnyMJ.ttf') format('truetype');\n  }\n  \n  @font-face {\n    font-family: 'SulekhaT';\n    src: url('./fonts/SulekhaT/SulekhaT.ttf') format('truetype');\n  }\n  \n  @font-face {\n    font-family: 'VillageII';\n    src: url('./fonts/VillageII/VILLIEB_.TTF') format('truetype');\n  }\n  \n  @font-face {\n    font-family: 'Roboto Condensed', sans-serif;\n    src: url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,300i,400,400i,700&display=swap') format('truetype');\n  }\n  \n  @font-face {\n    font-family: 'Gilroy';\n    src: url('./fonts/Gilroy/Gilroy-Regular.ttf') format('truetype');\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
