import http from '../helpers/http';


export const getLiveClasses = async (params) => {
  try {
    params = params || {};
    const config = { params }
    const { data } = await http.get(`/api/liveclasses`, config);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getLiveClass = async (id) => {
  try {
    const { data } = await http.get(`/api/liveclasses/${id}`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};
