import { getVimeoDownloadLink, getGotipathVideo } from "../services/video";


export const getVideoPayableUrl = async (lesson) => {
  try {
    const streamingPlatform = lesson?.streamingPlatform;
    const videoId = lesson.videoId;

    if (streamingPlatform === 'youtube') {
      return `https://www.youtube.com/watch?v=${videoId}`;
    } else if (streamingPlatform === 'vimeo') {
      const data = await getVimeoDownloadLink(videoId);
      return data.player_embed_url;
    } else if (streamingPlatform === 'gotipath') {
      const data = await getGotipathVideo(videoId);
      return data.status === 'playable' ? data.manifest_url : null;
    }
  } catch (error) {
    throw error;
  }
};
