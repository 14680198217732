import { useState } from 'react';
import toast from 'react-hot-toast';

import { Input } from '../../../components/forms/fields';
import { Sidepanel, SidepanelField } from '../../../components/Sidepanel';
import { sendCompetitionParticipantOtp } from '../../../services/userOTP';


export default function ParticipantLoginForm(props) {
  const { competition, onClose, onVerify } = props;
  const [participantId, setParticipantId] = useState('');
  const [participant, setParticipant] = useState(null);
  const [otp, setOtp] = useState('');
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [updating, setUpdating] = useState(false);

  const sendOtp = async () => {
    try {
      const payload = {
        participantId: participantId,
        currentRound: competition.currentRound
      };
      const data = await sendCompetitionParticipantOtp(competition._id, payload);
      setParticipant(data);
      // setMobile(data.mobile);
      setShowOtpInput(true);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleSubmit = async () => {
    setUpdating(true);
    if (showOtpInput) {
      await onVerify(participant._id, otp);
    } else {
      await sendOtp();
    }
    setUpdating(false);
  };

  return (
    <Sidepanel
      title="Participant login form"
      action={showOtpInput ? 'Verify' : 'Get OTP'}
      cancelText="Cancel"
      onClose={onClose}
      onSave={handleSubmit}
      updating={updating}
      disabled={updating || (!showOtpInput && !participantId)}
    >
      
      <SidepanelField label="Participant's Crew ID">
        <Input placeholder="Participant's Crew ID" value={participantId} onChange={(event) => setParticipantId(event.target.value)} />
      </SidepanelField>

      {showOtpInput &&
      <SidepanelField label="OTP">
        <Input placeholder="Enter otp" value={otp} onChange={(event) => setOtp(event.target.value)} />
        <p className="mt-2 text-xs text-gray-400">A 6 digit OTP has been sent to your mobile {participant.mobile}.</p>
      </SidepanelField>}
    </Sidepanel>
  )
};