import http from '../helpers/http';


export const getDownloadableLink = async (params) => {
  try {
    const config = { params: params || {} };
    const { data } = await http.get(`/api/downloads/public-link`, config);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};
