export default function SubmitButton(props) {
  const onClick = props.onClick;
  const action = props.action || 'Delete';
  const disabled = props.disabled;
  
  return (
    <button
      disabled={disabled}
      className="inline-flex justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-red disabled:opacity-30 disabled:cursor-not-allowed"
      onClick={onClick}
    >
      {action}
    </button>
  )
}
