import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import Layout from './components/Layout';
import { userActions } from '../../store/reducers/userReducers';
import { login } from '../../services/auth';
import { getCurrentUser } from '../../services/user';


export default function LoginPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.user);
  const currentUser = userState?.userInfo || null;
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');
  const [fetchingData, setFetchingData] = useState(false);

  useEffect(() => {
    if (currentUser) {
      goNext(currentUser);
    }
  }, []);

  const handleLogin = async (event) => {
    try {
      event.preventDefault();
      setFetchingData(true);
      let payload = { mobile, password };
      const data = await login(payload);
      if (data?.redirectUrl) {
        return navigate(data?.redirectUrl);
      }

      const _currentUser = await getCurrentUser();
      dispatch(userActions.setUserInfo(_currentUser));
      goNext(_currentUser);
    } catch (error) {
      setFetchingData(false);
      toast.error(error.message);
    }
  };

  const goNext = (_currentUser) => {
    if (!_currentUser.mobileVerified) {
      navigate(`/auth/otp/${currentUser._id}`);
    } else if (!_currentUser.isOnboarded) {
      navigate('/onboarding/personal-info');
    } else {
      navigate('/learn/home');
    }
  };

  return (
    <Layout
      title="আপনার অ্যাকাউন্টে লগ ইন করুন"
    >
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6">
          <div>
            <label className="block text-sm font-medium leading-6 text-gray-900">
              মোবাইল
            </label>
            <div className="mt-2">
              <input
                name="mobile"
                type="text"
                required
                className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="মোবাইল নাম্বার"
                onChange={(event) => setMobile(event.target.value)}
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                পাসওয়ার্ড
              </label>
              <div className="text-sm">
                <a href="/auth/forgot-password" className="text-red-600 hover:text-red-500">
                  পাসওয়ার্ড মনে নেই?
                </a>
              </div>
            </div>
            <div className="mt-2">
              <input
                name="password"
                type="password"
                required
                className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="পাসওয়ার্ড"
                onChange={(event) => setPassword(event.target.value)}
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              disabled={!mobile || !password || fetchingData}
              className="flex w-full justify-center rounded-md bg-[#27ac1f] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={handleLogin}
            >
              লগ ইন
            </button>
          </div>
        </form>

        <p className="mt-10 text-center text-sm text-gray-500">
          কোন অ্যাকাউন্ট নেই?{' '}
          <a href="/auth/register" 
            className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
          >
            এখনি ফ্রি রেজিস্ট্রেশন করতে করুন
          </a>
        </p>
      </div>
    </Layout>
  )
}
