import CourseCardGrid from './CourseCardGrid';


export default function CourseCardCompact(props) {
  const { course } = props;

  return (
    <CourseCardGrid course={course} isCompact={true} />
  )
};
