import http from '../helpers/http';


export const getZoomSignature = async () => {
  try {
    const { data } = await http.get(`/api/zoom/signature`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};
