import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/24/solid';

import StackedLayout from '../../layouts/StackedLayout';
import { images } from '../../constants';
import { getCourse } from '../../services/course';
import { getBatch } from '../../services/batch';
import { createBkashPayment, executeBkashPayment } from '../../services/payment';
import {
  convertToBanglaNumber,
  getDiscountedFee,
  gradeLabelMapping,
  groupLabelMapping,
} from '../../utils';


const courseTypeMapping = {
  live: 'লাইভ কোর্স',
  recorded: 'রেকর্ডেড কোর্স',
  exam: 'মডেল টেস্ট'
};

const paymentMethods = [
  { title: 'বিকাশ', value: 'bkash', icon: images.Bkash },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
};

export default function CourseCheckoutPage() {
  const userState = useSelector((state) => state.user);
  const currentUser = userState.userInfo || null;
  const navigate = useNavigate();
  const params = useParams();
  const courseId = params.id;
  const batchId = params.batchId;
  const [course, setCourse] = useState({});
  const [batch, setBatch] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(paymentMethods[0]);
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    if (!currentUser) { return navigate('/auth/login'); }
    if (!courseId) { return navigate(-1); }

    async function _fetchData() {
      await fetchCourse();
      setLoading(false);
    };
    _fetchData();
  }, []);

  const fetchCourse = async () => {
    try {
      const _course = await getCourse(courseId);
      if (!_course) { return navigate(-1); }
      setCourse(_course);

      if (_course.courseType === 'live') {
        const _batch = await getBatch(batchId);
        if (!_batch) {
          return navigate(-1);
        }
        setBatch(_batch);
      }
    } catch (error) {
      toast.error(error.message);
      navigate(-1);
    }
  };

  const pay = async () => {
    try {
      setProcessing(true);
      const gateway = selectedPaymentMethod.value;
      let paymentBody = {
          transactionType: 'course',
          courseId: courseId,
          batchId: batchId,
          gateway: gateway
      };
      if (gateway === 'bkash') {
        const bkashData = await createBkashPayment(paymentBody);
        window.location.href = bkashData.bkashUrl;
      } else {
          // To Do
      }
    } catch (error) {
      toast.error(error.message);
      setProcessing(false);
    }
  };
 
  return (
    <StackedLayout loading={loading}>
      <div className="mx-auto max-w-7xl px-4 pb-16 sm:px-6 sm:pb-24 sm:pt-8 lg:px-8 xl:px-2">
        <div className="mx-auto grid max-w-lg grid-cols-1 gap-x-8 gap-y-4 sm:gap-y-16 lg:max-w-none lg:grid-cols-2">
          <div className="mx-auto w-full max-w-lg">
            <div className="flow-root">
              <div className="flex sm:space-x-6">
                <img
                  src={course.thumbnail}
                  alt=""
                  className="hidden sm:block h-24 sm:h-24 flex-none rounded-md bg-gray-100 object-cover object-center"
                />
                <div className="flex-auto">
                  <div className="space-y-1 sm:flex sm:items-start sm:justify-between sm:space-x-6">
                    <div className="flex-auto space-y-1 text-sm font-medium">
                      <h3 className="text-gray-900 text-xl">
                        <a href={`/courses/${courseId}/details`}>{course.name}</a>
                      </h3>
                      <div className="flex items-center mt-2 text-sm text-gray-500 space-x-1">
                        <p>{courseTypeMapping[course.courseType]}</p>
                        <div className="h-1 w-1 rounded-full bg-gray-500" />
                        <p>{gradeLabelMapping[course.grade]}{course?.group ? ` (${groupLabelMapping[course.group]})` : ''}</p>
                      </div>
                      {course.courseType === 'live' && <p className="text-gray-600">ব্যাচঃ {batch.name}</p>}

                      {course.courseType !== 'live' &&
                      <p className="text-indigo-600">
                        কোর্সটির মেয়াদ{' '}
                        <span className="font-semibold">
                          {convertToBanglaNumber(course.validity)}
                        </span>
                        {' '}বছর
                      </p>}

                      <div className="mt-6 flex items-center text-sm text-gray-500 space-x-1">
                        <img className="h-6 w-6" src={course?.institute?.icon} alt="" />
                        <p>{course?.institute?.name}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <dl className="mt-4 sm:mt-10 space-y-6 text-sm font-medium text-gray-500">
              <div className="flex justify-between">
                <dt>কোর্স ফি</dt>
                <dd className="text-gray-900">৳{convertToBanglaNumber(course.fee)}</dd>
              </div>
              <div className="flex justify-between">
                <dt>
                  ডিস্কাউন্ট{course.discount > 0 && course.discountType === 'percentage' ? ` (${convertToBanglaNumber(course.discount)})%` : ''}
                  {/* <span className="text-xs text-red-400"> (-)</span> */}
                </dt>
                <dd className="text-gray-900">৳{convertToBanglaNumber(getDiscountAmount(course))}</dd>
              </div>
              <div className="flex justify-between">
                <dt>
                  প্রমোশনাল ডিস্কাউন্ট
                  {/* <span className="text-xs text-red-400"> (-)</span> */}
                </dt>
                <dd className="text-gray-900">৳০</dd>
              </div>
              <div className="flex justify-between border-t border-gray-200 pt-2 sm:pt-6 text-gray-900">
                <dt className="text-base">সর্বমোট <span className="text-xs text-gray-500">(*সকল ভ্যাট ও ট্যাক্সসহ)</span></dt>
                <dd className="text-base">৳{convertToBanglaNumber(getDiscountedFee(course))}</dd>
              </div>
            </dl>
          </div>

          <div className="mx-auto mt-2 sm:mt-0 w-full max-w-lg">
            <div className="pb-10">
              <RadioGroup value={selectedPaymentMethod} onChange={setSelectedPaymentMethod}>
                <RadioGroup.Label className="text-lg font-medium text-gray-900">পেমেন্ট পদ্ধতি</RadioGroup.Label>

                <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                  {paymentMethods.map((paymentMethod) => (
                    <RadioGroup.Option
                      key={paymentMethod.value}
                      value={paymentMethod}
                      className={({ checked, active }) =>
                        classNames(
                          checked ? 'border-transparent' : 'border-gray-300',
                          active ? 'ring-2 ring-green-500' : '',
                          'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none'
                        )
                      }
                    >
                      {({ checked, active }) => (
                        <>
                          <span className="flex flex-1">
                            <span className="flex flex-col">
                              <RadioGroup.Label as="span" className="flex items-center space-x-1 text-sm font-medium text-gray-900">
                                <img className="h-6 text-green-600" src={paymentMethod.icon} alt="" />
                                <span>{paymentMethod.title}</span>
                              </RadioGroup.Label>
                              {/* <RadioGroup.Description
                                as="span"
                                className="mt-1 flex items-center text-sm text-gray-500"
                              >
                                এই মুহূর্তে কোন অফার নেই
                              </RadioGroup.Description> */}
                            </span>
                          </span>
                          {checked ? (
                            <CheckCircleIcon className="h-5 w-5 text-green-600" aria-hidden="true" />
                          ) : null}
                          <span
                            className={classNames(
                              active ? 'border' : 'border-2',
                              checked ? 'border-green-500' : 'border-transparent',
                              'pointer-events-none absolute -inset-px rounded-lg'
                            )}
                            aria-hidden="true"
                          />
                        </>
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
            </div>

            <p className="mb-2 text-sm text-center underline text-indigo-600">
              <a href="/terms" target="_blank">এই পেমেন্ট করার জন্য আমি শর্তাবলী বুঝেছি</a>
            </p>
            <button
              type="button"
              disabled={!selectedPaymentMethod || processing}
              className="flex w-full items-center justify-center rounded-md border border-transparent bg-green-600 py-2 text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-green-900 focus:ring-offset-2"
              onClick={() => pay()}
            >
              পেমেন্ট করুন
            </button>
          </div>
        </div>
      </div>
    </StackedLayout>
  )
};

function getDiscountAmount(course) {
  const fee = course.fee;
  const discountValue = course.discount || 0;

  return course.discountType === 'flat' ? discountValue : (fee * discountValue) / 100;
}